import React from "react";
import {
  Link,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ControllerRenderProps, UseFormRegisterReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AnyData } from "../../../common/types";

type TextFieldPasswordProps = TextFieldProps & {
  formRegister?:
    | ControllerRenderProps<AnyData, AnyData>
    | UseFormRegisterReturn;
  generatePassword?: () => void;
};

const TextFieldPassword: React.FC<TextFieldPasswordProps> = ({
  formRegister,
  generatePassword,
  ...props
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickGeneratePassword = () => {
    if (!generatePassword) return;

    generatePassword();
    setShowPassword(true);
  };

  return (
    <>
      <TextField
        {...props}
        {...formRegister}
        type={showPassword ? "text" : "password"}
        
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {generatePassword && (
        <Link
          sx={{ display: "block", cursor: "pointer" }}
          textAlign="center"
          underline="none"
          variant="body2"
          onClick={handleClickGeneratePassword}
        >
          {t("components.textFieldPassword.generatePassword")}
        </Link>
      )}
    </>
  );
};

export default TextFieldPassword;
