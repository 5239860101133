/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RemoteStartTransactionDTO } from '../models/RemoteStartTransactionDTO';
import type { RemoteStopTransactionDTO } from '../models/RemoteStopTransactionDTO';
import type { ResetEcsDTO } from '../models/ResetEcsDTO';
import type { UpdateFirmwareEcsDTO } from '../models/UpdateFirmwareEcsDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EcsOcppReqService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Начать зарядку
     * Отправляем запрос "RemoteStartTransaction" с сервера в станцию
     * @returns any Станция приняла запрос.
     * @throws ApiError
     */
    public remoteStartTransaction({
        requestBody,
    }: {
        requestBody: RemoteStartTransactionDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs/ocpp/start-transaction',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Не найден ECS коннектор или статус не равен Available, коннектор забронирован, станция отклонила запрос, нужно оплатить предыдущую зарядку`,
                401: `Неверные данные для авторизации`,
                403: `Запрос разрешен только для пользователей с ролью "user"`,
                404: `Не найден ECS или OCPP Client`,
            },
        });
    }
    /**
     * Остановить зарядку
     * Отправляем запрос "RemoteStopTransaction" с сервера в станцию
     * @returns any Станция приняла запрос
     * @throws ApiError
     */
    public remoteStopTransaction({
        requestBody,
    }: {
        requestBody: RemoteStopTransactionDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs/ocpp/stop-transaction',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Запрос разрешен только для пользователей с ролью "user"`,
                404: `Не найден ECS или OCPP Client`,
            },
        });
    }
    /**
     * Сброс станции
     * Отправляем запрос "Reset" с сервера в станцию
     * @returns any Станция приняла запрос
     * @throws ApiError
     */
    public resetEcs({
        requestBody,
    }: {
        requestBody: ResetEcsDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs/ocpp/reset',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Запрос разрешен только для пользователей с ролью partner,admin`,
                404: `Не найден ECS или OCPP Client. Или станция не принадлежит партнеру.`,
            },
        });
    }
    /**
     * Обновление ПО станции
     * Отправляем запрос "UpdateFirmware" с сервера в станцию
     * @returns any Станция приняла запрос
     * @throws ApiError
     */
    public updateFirmwareEcs({
        requestBody,
    }: {
        requestBody: UpdateFirmwareEcsDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs/ocpp/update-firmware',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Запрос разрешен только для пользователей с ролью partner,admin`,
                404: `Не найден ECS или OCPP Client. Или станция не принадлежит партнеру.`,
            },
        });
    }
}
