import React, { FC, PropsWithChildren } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { muiLocales, SupportedLocales } from "../i18n";
import { customTheme } from "./customizationTheme";
import UserContext from "../store/contexts/user-context";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import { getDateLocale } from "../common/utils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const ThemeWrapper: FC<PropsWithChildren> = ({ children }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const {
    data: { adminPanelInfo },
  } = UserContext.useContext();
  const theme = adminPanelInfo && customTheme(adminPanelInfo.webTheme);
  const themeWithLocale = React.useMemo(() => {
    const dateLang = getDateLocale(language);
    if (dateLang && dateLang.key !== "en") dateLang.import;
    return createTheme(theme, muiLocales[language as SupportedLocales]);
  }, [language, theme]);

  return (
    <ThemeProvider theme={themeWithLocale}>
      <CssBaseline />
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={getDateLocale(language)?.key}
      >
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default ThemeWrapper;
