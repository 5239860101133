import React, { FC, PropsWithChildren } from "react";
import CustomSnackbar from "../components/CustomSnackbar";
import SnackbarProvider from "./providers/snackbar-provider";
import YookassaWidgetProvider from "./providers/yookassaWidget-provider";

import UserProvider from "./providers/user-provider";

const StoreWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SnackbarProvider>
      <YookassaWidgetProvider>
        <UserProvider>
          {children}
          <CustomSnackbar />
        </UserProvider>
      </YookassaWidgetProvider>
    </SnackbarProvider>
  );
};

export default StoreWrapper;
