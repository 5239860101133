import { isExpired } from "react-jwt";
import appClient from "src/api/appClient";

export const refreshTokenHook = () => {
  const refreshToken = async (): Promise<string> => {
    const refreshToken = localStorage.getItem("refreshToken") || "";
    if (!refreshToken) return "";
    if (isExpired(refreshToken)) return "";

    const { accessToken } = await appClient.auth.refreshTokens();

    localStorage.setItem("accessToken", accessToken);

    return accessToken;
  };

  return {
    refreshToken,
  };
};
