import { TFunction } from "i18next";

interface ErrorData {
  statusCode?: number;
  errorCode?: string;
  isCustomMessage?: boolean;
  error?: string;
  message?: string;
  messages?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorCodeOrMessageError = (err: any, t?: TFunction): string => {
  const data: ErrorData = err.body;

  if (!data || typeof data === "string") return err?.message || err?.statusText;

  if (data.errorCode && !data.isCustomMessage)
    return t ? t(`errorCode.${data.errorCode}`) : data.errorCode;

  if (data.message) return data.message;

  return "";
};
