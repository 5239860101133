/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePaymentConfirmationResponseDTO } from './CreatePaymentConfirmationResponseDTO';
export type CreatePaymentResponseDTO = {
    id: string;
    status: CreatePaymentResponseDTO.status;
    /**
     * Если оплата полностью с баланса
     */
    isFullBalance?: boolean;
    confirmation?: CreatePaymentConfirmationResponseDTO;
};
export namespace CreatePaymentResponseDTO {
    export enum status {
        WAITING_FOR_CAPTURE = 'waiting_for_capture',
        PENDING = 'pending',
        SUCCEEDED = 'succeeded',
        CANCELED = 'canceled',
    }
}

