import React, { useState, PropsWithChildren } from "react";
import Context from "../contexts/context";

export type ProviderProps = PropsWithChildren;

type IProvider<T> = PropsWithChildren & {
  initialState: T;
  ContextComponent: Context<T>;
}

export type UpdateData<T> = {
  [K in keyof Partial<T>]: T[K];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Provider = <T extends Record<string, any>>({
  children,
  initialState,
  ContextComponent,
}: IProvider<T>) => {
  const [data, setData] = useState<T>(initialState);
//
  const updateData = (newData: UpdateData<T>) =>
    setData((prev) => ({ ...prev, ...newData }));

  const value = {
    data,
    setData: updateData,
  };

  return (
    <ContextComponent.context.Provider value={value}>
      {children}
    </ContextComponent.context.Provider>
  );
};

export default Provider;
