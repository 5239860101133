/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateReserveDTO } from '../models/CreateReserveDTO';
import type { EcsConnectorReserveDatesResponseDTO } from '../models/EcsConnectorReserveDatesResponseDTO';
import type { OpenReserveEcsParkingDTO } from '../models/OpenReserveEcsParkingDTO';
import type { PageReserveResponseDTO } from '../models/PageReserveResponseDTO';
import type { ReserveEcsResponseDTO } from '../models/ReserveEcsResponseDTO';
import type { ReserveResponseDTO } from '../models/ReserveResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EcsReserveService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Резервация
     * Создание резервации коннектора станции для зарядки
     * @returns void
     * @throws ApiError
     */
    public createReserve({
        requestBody,
    }: {
        requestBody: CreateReserveDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs/reserve',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка резервирования, не найден ECS или уже занято`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Открыть парковку коннектора
     * @returns void
     * @throws ApiError
     */
    public openReserveEcsParking({
        requestBody,
    }: {
        requestBody: OpenReserveEcsParkingDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs/reserve/open-parking',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Станция не поддерживает открытие парковки`,
                401: `Неверные данные для авторизации`,
                404: `Нет брони`,
            },
        });
    }
    /**
     * Отменить резервацию
     * @returns void
     * @throws ApiError
     */
    public cancelReserve({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs/reserve/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав или статус резервации не active`,
            },
        });
    }
    /**
     * Удалить резервацию
     * @returns void
     * @throws ApiError
     */
    public deleteReserve({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ecs/reserve/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Резервация
     * @returns ReserveResponseDTO
     * @throws ApiError
     */
    public getReserve({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<ReserveResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/reserve/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Список резерваций
     * @returns PageReserveResponseDTO Список резерваций пользователя
     * @throws ApiError
     */
    public getReserveList({
        startDate,
        endDate,
        page = 1,
        limit = 10,
    }: {
        startDate?: string,
        endDate?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PageReserveResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/reserve/list',
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Получить информацию о резервации станции
     * Получение резервации станции для зарядки
     * @returns ReserveEcsResponseDTO
     * @throws ApiError
     */
    public getReserveEcs({
        id = '61d9cfbf17ed7311c4b3e485',
        ecsConnector,
    }: {
        /**
         * Id
         */
        id?: string,
        ecsConnector?: string,
    }): CancelablePromise<Array<ReserveEcsResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/reserve/ecs/{id}',
            path: {
                'id': id,
            },
            query: {
                'ecsConnector': ecsConnector,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден ECS или коннектор`,
            },
        });
    }
    /**
     * Получить ближайшую бронь коннектора
     * Получить ближайшую бронь коннектора. startDate = текущая дата и  endDate = текущая дата + 30 минут или указанное значение
     * @returns ReserveEcsResponseDTO
     * @throws ApiError
     */
    public getEcsConnectorNearestReserve({
        ecs,
        ecsConnector,
        endDate,
    }: {
        ecs: string,
        ecsConnector: string,
        /**
         * Если не указана то будет текущая дата + 30 минут
         */
        endDate?: string,
    }): CancelablePromise<ReserveEcsResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/reserve/ecs-connector-nearest',
            query: {
                'ecs': ecs,
                'ecsConnector': ecsConnector,
                'endDate': endDate,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Получить даты резервации станции
     * Получить даты резервации станции по коннекторам
     * @returns EcsConnectorReserveDatesResponseDTO
     * @throws ApiError
     */
    public getReserveEcsDates({
        id = '61d9cfbf17ed7311c4b3e485',
        ecsConnector,
    }: {
        /**
         * Id
         */
        id?: string,
        ecsConnector?: string,
    }): CancelablePromise<EcsConnectorReserveDatesResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/reserve/ecs-dates/{id}',
            path: {
                'id': id,
            },
            query: {
                'ecsConnector': ecsConnector,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден ECS или коннектор`,
            },
        });
    }
}
