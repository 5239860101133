import i18n, { ResourceLanguage } from "i18next";
import { initReactI18next } from "react-i18next";
import * as locales from "@mui/material/locale";

export type SupportedLocales = keyof typeof locales;
export const muiLocales = locales;

type Resource = {
  [language in SupportedLocales]?: ResourceLanguage;
}

const resources: Resource = {
  ruRU: {
    translation: require("./locales/ru.json"),
  },
  enUS: {
    translation: require("./locales/en.json"),
  },
};

i18n.use(initReactI18next).init({
  returnNull: false,
  resources,
  lng: "ruRU",
  fallbackLng: "ruRU",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
