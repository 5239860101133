/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfilePartResponseDTO } from './ProfilePartResponseDTO';
export type PaymentTransactionResponseDTO = {
    _id: string;
    owner?: (string | ProfilePartResponseDTO);
    ecsOwner?: (string | ProfilePartResponseDTO);
    title: string;
    description: string;
    amount: number;
    refundAmount: number;
    commissionPartner: number;
    fromBalance: number;
    isFullBalance: boolean;
    type: PaymentTransactionResponseDTO.type;
    category: PaymentTransactionResponseDTO.category;
    status: PaymentTransactionResponseDTO.status;
    isHoldPartnerBalance: boolean;
    createdAt: string;
};
export namespace PaymentTransactionResponseDTO {
    export enum type {
        PAYMENT = 'payment',
        REFUND = 'refund',
    }
    export enum category {
        ADD_PAYMENT_METHOD = 'addPaymentMethod',
        ECS_START_TRANSACTION = 'ecsStartTransaction',
        ECS_CHARGING = 'ecsCharging',
        ECS_RESERVE = 'ecsReserve',
        REPLENISH_BALANCE = 'replenishBalance',
    }
    export enum status {
        WAITING_FOR_CAPTURE = 'waiting_for_capture',
        PENDING = 'pending',
        SUCCEEDED = 'succeeded',
        CANCELED = 'canceled',
    }
}

