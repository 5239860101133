/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SendDocumentsBusinessResponseDTO } from './SendDocumentsBusinessResponseDTO';
import type { UserAdminPanelInfoDTO } from './UserAdminPanelInfoDTO';
import type { UserSettingsResponseDTO } from './UserSettingsResponseDTO';
export type MyProfileResponseDTO = {
    _id: string;
    phone: string;
    email: string;
    firstName: string;
    lastName: string;
    avatar: string;
    roles: Array<'user' | 'partner' | 'operator' | 'admin'>;
    theme: MyProfileResponseDTO.theme;
    status: MyProfileResponseDTO.status;
    referrer?: string;
    referralCode: string;
    lastLogin: string;
    createdAt: string;
    updatedAt: string;
    settings: UserSettingsResponseDTO;
    /**
     * Есть только для роли partner
     */
    balancePartner?: number;
    balance: number;
    /**
     * Есть только если передать в запрос isAdminPanel=true
     */
    adminPanelInfo?: UserAdminPanelInfoDTO;
    documentsBusiness?: SendDocumentsBusinessResponseDTO;
};
export namespace MyProfileResponseDTO {
    export enum theme {
        SYSTEM = 'system',
        LIGHT = 'light',
        DARK = 'dark',
    }
    export enum status {
        OPENING = 'opening',
        PENDING = 'pending',
        DECLINE = 'decline',
        AWAITING_SIGNATURE = 'awaitingSignature',
        PENDING_SIGNATURE = 'pendingSignature',
        DECLINE_SIGNATURE = 'declineSignature',
        APPROVE = 'approve',
    }
}

