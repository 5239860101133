/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateEcsDTO = {
    name: string;
    address?: string;
    description?: string;
    latLong: string;
    type: CreateEcsDTO.type;
    isActive: boolean;
};
export namespace CreateEcsDTO {
    export enum type {
        SLOW = 'slow',
        FAST = 'fast',
    }
}

