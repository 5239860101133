import { SignInResponseDTO } from "src/api/openapi";

export const tokenHook = () => {
  const getAccessToken = (): string => {
    const accessToken = localStorage.getItem("accessToken") || "";

    return accessToken;
  };

  const getRefreshToken = (): string =>
    localStorage.getItem("refreshToken") || "";

  const setTokens = (data: SignInResponseDTO, isAdmin = false) => {
    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("refreshToken", data.refreshToken);
    if (isAdmin) localStorage.setItem("isAdmin", "1");
  };

  const getToken = (isRefresh = false): string =>
    !isRefresh ? getAccessToken() : getRefreshToken();

  const removeTokens = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("isAdmin");
  };

  return {
    getAccessToken,
    setTokens,
    removeTokens,
    getToken,
  };
};
