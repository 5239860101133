/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ReserveEcsResponseDTO = {
    _id: string;
    owner: string;
    ecsConnector: string;
    amount: number;
    isPaymentBalance: boolean;
    startDate: string;
    endDate: string;
    status: ReserveEcsResponseDTO.status;
    ecs: string;
    car: string;
};
export namespace ReserveEcsResponseDTO {
    export enum status {
        CANCELED = 'canceled',
        ACTIVE = 'active',
        CHARGING = 'charging',
        COMPLETED = 'completed',
    }
}

