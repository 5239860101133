/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEcsDTO } from '../models/CreateEcsDTO';
import type { EcsResponseDTO } from '../models/EcsResponseDTO';
import type { PageEcsResponseDTO } from '../models/PageEcsResponseDTO';
import type { UpdateEcsDTO } from '../models/UpdateEcsDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EcsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Создать ECS
     * @returns void
     * @throws ApiError
     */
    public create({
        requestBody,
    }: {
        requestBody: CreateEcsDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Добавить в избранное ECS
     * @returns void
     * @throws ApiError
     */
    public addFavorite({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs/{id}/favorite-add',
            path: {
                'id': id,
            },
            errors: {
                400: `Неверные данные - не найден пользователь или ECS`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Удалить из избранного ECS
     * @returns void
     * @throws ApiError
     */
    public removeFavorite({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs/{id}/favorite-remove',
            path: {
                'id': id,
            },
            errors: {
                400: `Неверные данные - не найден пользователь или ECS`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Обновить ECS
     * @returns void
     * @throws ApiError
     */
    public update({
        requestBody,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        requestBody: UpdateEcsDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ecs/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Удалить ECS
     * @returns void
     * @throws ApiError
     */
    public delete({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ecs/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * ECS
     * @returns EcsResponseDTO ECS
     * @throws ApiError
     */
    public getEcs({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<EcsResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден ECS`,
            },
        });
    }
    /**
     * Список ECS
     * Список ECS с пагинацией
     * @returns PageEcsResponseDTO Список Ecs
     * @throws ApiError
     */
    public getEcsList({
        search,
        connectorTypes,
        statuses,
        types,
        isFree,
        isFavorite,
        owner,
        sortLocation,
        page = 1,
        limit = 10,
    }: {
        /**
         * Поиск по имени станции.
         */
        search?: string,
        connectorTypes?: Array<'none' | 'type1' | 'type2' | 'chademo' | 'cssC1' | 'cssC2' | 'gbt'>,
        statuses?: Array<'Available' | 'Occupied' | 'Charging' | 'Reserved' | 'Unavailable' | 'Faulted'>,
        types?: Array<'slow' | 'fast'>,
        isFree?: boolean,
        isFavorite?: boolean,
        owner?: string,
        /**
         * The point for which we return the documents from the nearest to the farthest.
         */
        sortLocation?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PageEcsResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/list',
            query: {
                'search': search,
                'connectorTypes': connectorTypes,
                'statuses': statuses,
                'types': types,
                'isFree': isFree,
                'isFavorite': isFavorite,
                'owner': owner,
                'sortLocation': sortLocation,
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Список ECS для карты
     * Список ECS без пагинации
     * @returns EcsResponseDTO Список ECS
     * @throws ApiError
     */
    public getMapAll({
        search,
        connectorTypes,
        statuses,
        types,
        isFree,
        isFavorite,
        owner,
        sortLocation,
    }: {
        /**
         * Поиск по имени станции.
         */
        search?: string,
        connectorTypes?: Array<'none' | 'type1' | 'type2' | 'chademo' | 'cssC1' | 'cssC2' | 'gbt'>,
        statuses?: Array<'Available' | 'Occupied' | 'Charging' | 'Reserved' | 'Unavailable' | 'Faulted'>,
        types?: Array<'slow' | 'fast'>,
        isFree?: boolean,
        isFavorite?: boolean,
        owner?: string,
        /**
         * The point for which we return the documents from the nearest to the farthest.
         */
        sortLocation?: string,
    }): CancelablePromise<Array<EcsResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/list/map',
            query: {
                'search': search,
                'connectorTypes': connectorTypes,
                'statuses': statuses,
                'types': types,
                'isFree': isFree,
                'isFavorite': isFavorite,
                'owner': owner,
                'sortLocation': sortLocation,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
}
