import { SnackBarData } from "../../common/types";
import Context from "./context";

export const initialSnackbarState: SnackBarData = {
  open: false,
  duration: 5000,
  message: "",
  type: "info",
  vertical: "top",
  horizontal: "right",
};

const SnackbarContext = new Context(initialSnackbarState);

export default SnackbarContext;
