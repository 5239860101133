/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePaymentTransactionResponseDTO } from '../models/PagePaymentTransactionResponseDTO';
import type { PaymentTransactionResponseDTO } from '../models/PaymentTransactionResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentTransactionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Список транзакций оплаты
     * @returns PagePaymentTransactionResponseDTO Список транзакций оплаты
     * @throws ApiError
     */
    public getPaymentTransactionsList({
        createdAtFrom,
        createdAtTo,
        page = 1,
        limit = 10,
    }: {
        createdAtFrom?: string,
        createdAtTo?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PagePaymentTransactionResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/transaction/list',
            query: {
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Транзакция оплаты
     * @returns PaymentTransactionResponseDTO Транзакция оплаты
     * @throws ApiError
     */
    public getPaymentTransaction({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<PaymentTransactionResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/transaction/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Транзакция не найдена`,
            },
        });
    }
}
