/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SendDocumentsBusinessDTO = {
    /**
     * Нужно если type != self-employed
     */
    companyName?: string;
    type: SendDocumentsBusinessDTO.type;
    passport: Blob;
    /**
     * Нужно если type != self-employed
     */
    itn?: Blob;
};
export namespace SendDocumentsBusinessDTO {
    export enum type {
        SOLE_PROPRIETOR = 'soleProprietor',
        SELF_EMPLOYED = 'self-employed',
        LLC = 'LLC',
    }
}

