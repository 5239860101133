/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AdminUserService } from './services/AdminUserService';
import { AuthService } from './services/AuthService';
import { CarService } from './services/CarService';
import { DashboardService } from './services/DashboardService';
import { EcsService } from './services/EcsService';
import { EcsOcppReqService } from './services/EcsOcppReqService';
import { EcsOcppTransactionService } from './services/EcsOcppTransactionService';
import { EcsParkingService } from './services/EcsParkingService';
import { EcsPublicService } from './services/EcsPublicService';
import { EcsRateService } from './services/EcsRateService';
import { EcsReserveService } from './services/EcsReserveService';
import { FeedbackService } from './services/FeedbackService';
import { FileService } from './services/FileService';
import { NotificationService } from './services/NotificationService';
import { PaymentService } from './services/PaymentService';
import { PaymentTransactionService } from './services/PaymentTransactionService';
import { PayoutService } from './services/PayoutService';
import { PromoCodeService } from './services/PromoCodeService';
import { SseService } from './services/SseService';
import { SupportService } from './services/SupportService';
import { SupportChatService } from './services/SupportChatService';
import { SupportFaqService } from './services/SupportFaqService';
import { UserService } from './services/UserService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class AppClient {
    public readonly adminUser: AdminUserService;
    public readonly auth: AuthService;
    public readonly car: CarService;
    public readonly dashboard: DashboardService;
    public readonly ecs: EcsService;
    public readonly ecsOcppReq: EcsOcppReqService;
    public readonly ecsOcppTransaction: EcsOcppTransactionService;
    public readonly ecsParking: EcsParkingService;
    public readonly ecsPublic: EcsPublicService;
    public readonly ecsRate: EcsRateService;
    public readonly ecsReserve: EcsReserveService;
    public readonly feedback: FeedbackService;
    public readonly file: FileService;
    public readonly notification: NotificationService;
    public readonly payment: PaymentService;
    public readonly paymentTransaction: PaymentTransactionService;
    public readonly payout: PayoutService;
    public readonly promoCode: PromoCodeService;
    public readonly sse: SseService;
    public readonly support: SupportService;
    public readonly supportChat: SupportChatService;
    public readonly supportFaq: SupportFaqService;
    public readonly user: UserService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://dev.greengo.tech/api',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.adminUser = new AdminUserService(this.request);
        this.auth = new AuthService(this.request);
        this.car = new CarService(this.request);
        this.dashboard = new DashboardService(this.request);
        this.ecs = new EcsService(this.request);
        this.ecsOcppReq = new EcsOcppReqService(this.request);
        this.ecsOcppTransaction = new EcsOcppTransactionService(this.request);
        this.ecsParking = new EcsParkingService(this.request);
        this.ecsPublic = new EcsPublicService(this.request);
        this.ecsRate = new EcsRateService(this.request);
        this.ecsReserve = new EcsReserveService(this.request);
        this.feedback = new FeedbackService(this.request);
        this.file = new FileService(this.request);
        this.notification = new NotificationService(this.request);
        this.payment = new PaymentService(this.request);
        this.paymentTransaction = new PaymentTransactionService(this.request);
        this.payout = new PayoutService(this.request);
        this.promoCode = new PromoCodeService(this.request);
        this.sse = new SseService(this.request);
        this.support = new SupportService(this.request);
        this.supportChat = new SupportChatService(this.request);
        this.supportFaq = new SupportFaqService(this.request);
        this.user = new UserService(this.request);
    }
}

