/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateFeedbackDTO } from '../models/CreateFeedbackDTO';
import type { PageFeedbackResponseDTO } from '../models/PageFeedbackResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FeedbackService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Добавить обратную связь
     * @returns any
     * @throws ApiError
     */
    public createFeedback({
        requestBody,
    }: {
        requestBody: CreateFeedbackDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/feedback',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Список обратной связи
     * @returns PageFeedbackResponseDTO Список обратной связи
     * @throws ApiError
     */
    public getFeedbackList({
        page = 1,
        limit = 10,
        isCount = true,
    }: {
        page?: number,
        limit?: number,
        /**
         * Если он активно возвращает количество записей.
         */
        isCount?: boolean,
    }): CancelablePromise<PageFeedbackResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/feedback/list',
            query: {
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
}
