/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SupportChatUploadFileResponseDTO = {
    room: string;
    filePath: string;
    fileOriginalname: string;
    fileType: SupportChatUploadFileResponseDTO.fileType;
};
export namespace SupportChatUploadFileResponseDTO {
    export enum fileType {
        IMAGE = 'image',
        DOCUMENT = 'document',
    }
}

