/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CarResponseDTO } from '../models/CarResponseDTO';
import type { ChangeImageDTO } from '../models/ChangeImageDTO';
import type { CreateOrUpdateCarDTO } from '../models/CreateOrUpdateCarDTO';
import type { PageCarResponseDTO } from '../models/PageCarResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CarService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Добавить машину
     * @returns any
     * @throws ApiError
     */
    public createCar({
        requestBody,
    }: {
        requestBody: CreateOrUpdateCarDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/car',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Обновить машину
     * @returns void
     * @throws ApiError
     */
    public updateCar({
        requestBody,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        requestBody: CreateOrUpdateCarDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/car/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Удалить машину
     * @returns void
     * @throws ApiError
     */
    public deleteCar({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/car/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Машина
     * @returns CarResponseDTO Машина пользователя
     * @throws ApiError
     */
    public getCar({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<CarResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/car/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Машина не найдена`,
            },
        });
    }
    /**
     * Добавить или обновить фото
     * @returns string Ссылка на фото
     * @throws ApiError
     */
    public changeCarImage({
        formData,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        formData: ChangeImageDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/car/{id}/image',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Список машин
     * @returns PageCarResponseDTO Машины пользователя
     * @throws ApiError
     */
    public getCarList({
        page = 1,
        limit = 10,
    }: {
        page?: number,
        limit?: number,
    }): CancelablePromise<PageCarResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/car/list',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
}
