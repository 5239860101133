/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdatePromoCodeDTO = {
    name?: string;
    description?: string;
    type?: UpdatePromoCodeDTO.type;
    /**
     * Значение скидки
     */
    value?: number;
    isActive?: boolean;
};
export namespace UpdatePromoCodeDTO {
    export enum type {
        AMOUNT = 'amount',
        PERCENT = 'percent',
    }
}

