const config = {
  baseUrl: process.env.REACT_APP_BASE_URL || "",
  apiUrl: process.env.REACT_APP_API_URL || "",
  wsUrl: process.env.REACT_APP_WS_URL || "",
  ocppUrl: process.env.REACT_APP_OCPP_URL || "",
  yandexMapsApiKey: process.env.REACT_APP_YANDEX_MAPS_API_KEY || "",
  payouts: {
    agentId: process.env.REACT_APP_YOOKASSA_PAYOUTS_AGENT_ID || "",
    limitAndCommission: {
      card: {
        min: Number(process.env.REACT_APP_YOOKASSA_PAYOUTS_CARD_MIN) || 100,
        max: Number(process.env.REACT_APP_YOOKASSA_PAYOUTS_CARD_MAX) || 500000,
        commission: {
          value:
            Number(
              (
                Number(process.env.REACT_APP_YOOKASSA_PAYOUTS_CARD_COMMISSION) /
                100
              ).toFixed(3)
            ) || 0,
          amount:
            Number(
              process.env.REACT_APP_YOOKASSA_PAYOUTS_CARD_COMMISSION_AMOUNT
            ) || 0,
        },
      },
    },
  },
};

export default config;
