/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardStatisticResponseDTO } from '../models/DashboardStatisticResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DashboardService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Статистика
     * Общее количество и количество за 30 дней
     * @returns DashboardStatisticResponseDTO
     * @throws ApiError
     */
    public getDashboardStatistics(): CancelablePromise<Array<DashboardStatisticResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/dashboard/statistics',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
}
