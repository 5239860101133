/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateRefundResponseDTO = {
    id: string;
    status: CreateRefundResponseDTO.status;
};
export namespace CreateRefundResponseDTO {
    export enum status {
        CANCELED = 'canceled',
        SUCCEEDED = 'succeeded',
    }
}

