const random = (min = 0, max = 1) => {
  return Math.floor(Math.random() * (max + 1 - min) + min);
};

const randomLower = () => {
  return String.fromCharCode(random(97, 122));
};

const randomUpper = () => {
  return String.fromCharCode(random(65, 90));
};

const randomSymbol = () => {
  const symbols = "!@#$%^&*";
  return symbols[random(0, symbols.length - 1)];
};

export const generatePassword = (length = 10) => {
  let password = "";

  password += randomLower();
  password += randomUpper();
  password += randomSymbol();
  password += random(0, 9);

  for (let i = 0; i < length -4; i++) {
    const choice = random(0, 3);

    if (choice === 0) password += randomLower();
    if (choice === 1) password += randomUpper();
    if (choice === 2) password += randomSymbol();
    if (choice === 3) password += random(0, 9);
  }

  return password;
};
