/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreatePaymentMetadataTransactionDTO = {
    transactionCategory: CreatePaymentMetadataTransactionDTO.transactionCategory;
    /**
     * Нужно для transactionCategory = ecsCharging, ecsReserve
     */
    ecsOwner?: string;
    /**
     * Нужно для transactionCategory = ecsCharging. Это _id транзакции зарядки
     */
    sourceId?: string;
};
export namespace CreatePaymentMetadataTransactionDTO {
    export enum transactionCategory {
        ADD_PAYMENT_METHOD = 'addPaymentMethod',
        ECS_START_TRANSACTION = 'ecsStartTransaction',
        ECS_CHARGING = 'ecsCharging',
        ECS_RESERVE = 'ecsReserve',
        REPLENISH_BALANCE = 'replenishBalance',
    }
}

