import React from "react";
import { Tooltip, Box } from "@mui/material";
import { getTextTruncate } from "../../common/utils";

interface TextTruncateProps {
  text: string;
  limit: number;
}

const TextTruncate: React.FC<TextTruncateProps> = ({ text, limit }) => {
  const textTruncate = getTextTruncate(text, limit);
  if (text.length <= limit) return <>{text}</>;
  return <Tooltip title={text}><Box>{textTruncate}</Box></Tooltip>;
};

export default TextTruncate;
