/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreatePromoCodeDTO = {
    name: string;
    description: string;
    type: CreatePromoCodeDTO.type;
    /**
     * Значение скидки
     */
    value: number;
    isActive: boolean;
};
export namespace CreatePromoCodeDTO {
    export enum type {
        AMOUNT = 'amount',
        PERCENT = 'percent',
    }
}

