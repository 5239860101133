/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ForgotPasswordDTO } from '../models/ForgotPasswordDTO';
import type { RefreshTokenResponseDTO } from '../models/RefreshTokenResponseDTO';
import type { ResetPasswordDTO } from '../models/ResetPasswordDTO';
import type { SendPhoneCodeDTO } from '../models/SendPhoneCodeDTO';
import type { SendPhoneCodeResponseDTO } from '../models/SendPhoneCodeResponseDTO';
import type { SignInEmailDTO } from '../models/SignInEmailDTO';
import type { SignInPhoneDTO } from '../models/SignInPhoneDTO';
import type { SignInResponseDTO } from '../models/SignInResponseDTO';
import type { SignUpPartnerDTO } from '../models/SignUpPartnerDTO';
import type { UserInfoResponseDTO } from '../models/UserInfoResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Отправить код авторизации на телефон
     * @returns SendPhoneCodeResponseDTO Вернем время жизни кода авторизации
     * @throws ApiError
     */
    public sendPhoneCode({
        requestBody,
    }: {
        requestBody: SendPhoneCodeDTO,
    }): CancelablePromise<SendPhoneCodeResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/send-phone-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Превышен лимит запросов с 1 ip адреса.`,
                403: `Пользователь был заблокирован и не может авторизоваться`,
            },
        });
    }
    /**
     * Авторизация по номеру и коду
     * @returns SignInResponseDTO Время жизни токенов, access и refresh токены
     * @throws ApiError
     */
    public signInPhone({
        requestBody,
    }: {
        requestBody: SignInPhoneDTO,
    }): CancelablePromise<SignInResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/signin-phone',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверный код, истекло время жизни кода, превышено количество попыток ввода кода, пользователь был заблокирован и не может авторизоваться`,
                403: `Пользователь не найден`,
            },
        });
    }
    /**
     * Авторизация по емайлу и паролю
     * @returns SignInResponseDTO Время жизни токенов, access и refresh токены
     * @throws ApiError
     */
    public signInEmail({
        requestBody,
    }: {
        requestBody: SignInEmailDTO,
    }): CancelablePromise<SignInResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/signin-email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверные данные(пользователь не найден), пользователь был заблокирован и не может авторизоваться`,
            },
        });
    }
    /**
     * Забыли пароль
     * @returns any Ссылка для сброса пароля отправлена на емайл
     * @throws ApiError
     */
    public forgotPassword({
        requestBody,
    }: {
        requestBody: ForgotPasswordDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Пользователь не найден`,
            },
        });
    }
    /**
     * Сброс пароля
     * @returns void
     * @throws ApiError
     */
    public resetPassword({
        requestBody,
    }: {
        requestBody: ResetPasswordDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/reset-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверные данные, время жизни токена сброса пароля истекло, пользователь не найден`,
            },
        });
    }
    /**
     * Регистрация партнера
     * @returns SignInResponseDTO Время жизни токенов, access и refresh токены
     * @throws ApiError
     */
    public signUpPartner({
        requestBody,
    }: {
        requestBody: SignUpPartnerDTO,
    }): CancelablePromise<SignInResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/signup-partner',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Емайл или номер телефона занят`,
            },
        });
    }
    /**
     * Обновить токен
     * Используйте refreshToken для авторизации этого запроса
     * @returns RefreshTokenResponseDTO Access токен
     * @throws ApiError
     */
    public refreshTokens(): CancelablePromise<RefreshTokenResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/refresh',
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Пользователь не найден, пользователь был заблокирован и не может авторизоваться`,
            },
        });
    }
    /**
     * Данные авторизованного пользователя
     * @returns UserInfoResponseDTO Данные авторизованного пользователя
     * @throws ApiError
     */
    public getUserInfo({
        isAdminPanel,
    }: {
        isAdminPanel?: boolean,
    }): CancelablePromise<UserInfoResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user-info',
            query: {
                'isAdminPanel': isAdminPanel,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Пользователь не найден`,
            },
        });
    }
}
