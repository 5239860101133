import React from "react";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { SelectItem } from "../../../common/types";

interface SelectFormProps {
  label: string;
  items: SelectItem[];
  defaultValue?: SelectItem["value"];
  formControl?: FormControlProps;
  selectProps?: SelectProps<SelectItem['value']>;
  errorMessage?: string;
}

const SelectForm: React.FC<SelectFormProps> = ({
  formControl,
  selectProps,
  errorMessage,
  defaultValue = "",
  label,
  items,
}) => {
  return (
    <FormControl {...formControl}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        defaultValue={defaultValue}
        label={label}
        notched
        displayEmpty
        {...selectProps}
      >
        {items.map((item, i) => (
          <MenuItem key={i} {...item}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default SelectForm;
