import React, { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRouter from "./routes/PrivateRouter";
import StoreWrapper from "./store/StoreWrapper";
import ThemeWrapper from "./theme/ThemeWrapper";

const Login = lazy(() => import("./pages/public/Login"));
const SignUpPartner = lazy(() => import("./pages/public/SignUpPartner"));
const ForgotPassword = lazy(() => import("./pages/public/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/public/ResetPassword"));
const ContactUs = lazy(() => import("./pages/public/ContactUs"));

function App() {
  return (
    <StoreWrapper>
      <ThemeWrapper>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/login/admin" element={<Login authAdmin />} />
            <Route path="/sign-up" element={<SignUpPartner />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/*" element={<PrivateRouter />} />
          </Routes>
        </BrowserRouter>
      </ThemeWrapper>
    </StoreWrapper>
  );
}

export default App;
