/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SupportFaqResponseDTO = {
    _id: string;
    title: string;
    description: string;
    /**
     * Есть только для роли админ
     */
    position: number;
    /**
     * Есть только для роли админ
     */
    role: SupportFaqResponseDTO.role;
    createdAt: string;
    updatedAt: string;
};
export namespace SupportFaqResponseDTO {
    /**
     * Есть только для роли админ
     */
    export enum role {
        NOT_AUTH = 'notAuth',
        ALL_AUTH_USER = 'allAuthUser',
        USER = 'user',
        PARTNER = 'partner',
    }
}

