/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreatePaymentConfirmationResponseDTO = {
    type: CreatePaymentConfirmationResponseDTO.type;
    /**
     * Ссылка для ввода кода подтверждения для типа redirect
     */
    confirmationUrl?: string;
};
export namespace CreatePaymentConfirmationResponseDTO {
    export enum type {
        EMBEDDED = 'embedded',
        EXTERNAL = 'external',
        QR = 'qr',
        REDIRECT = 'redirect',
    }
}

