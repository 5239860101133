/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SendDocumentsBusinessResponseDTO = {
    /**
     * Есть если type != self-employed
     */
    companyName: string;
    type: SendDocumentsBusinessResponseDTO.type;
    passport: string;
    itn: string;
    contract: string;
};
export namespace SendDocumentsBusinessResponseDTO {
    export enum type {
        SOLE_PROPRIETOR = 'soleProprietor',
        SELF_EMPLOYED = 'self-employed',
        LLC = 'LLC',
    }
}

