/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPaymentMethodDTO } from '../models/AddPaymentMethodDTO';
import type { CreatePaymentDTO } from '../models/CreatePaymentDTO';
import type { CreatePaymentResponseDTO } from '../models/CreatePaymentResponseDTO';
import type { CreateRefundResponseDTO } from '../models/CreateRefundResponseDTO';
import type { CreateRefundTransactionDTO } from '../models/CreateRefundTransactionDTO';
import type { PaymentMethodResponseDTO } from '../models/PaymentMethodResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Оплата
     * Оплата сохраненным методом оплаты или новым методом оплаты. Если нет данных paymentToken, confirmation и paymentMethodId то будем использовать метод оплаты по умолчанию если он есть.
     * @returns CreatePaymentResponseDTO
     * @throws ApiError
     */
    public createPayment({
        requestBody,
    }: {
        requestBody: CreatePaymentDTO,
    }): CancelablePromise<CreatePaymentResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка создания платежа, не найден ECS или метод оплаты, срок действия карты истек`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Возврат оплаты
     * Возврат транзакции оплаты.
     * @returns CreateRefundResponseDTO
     * @throws ApiError
     */
    public createRefundTransaction({
        requestBody,
    }: {
        requestBody: CreateRefundTransactionDTO,
    }): CancelablePromise<CreateRefundResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/refund-transaction',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Транзакция не найдена. Сумма возврата больше доступной суммы возврата. Ошибка юкассы.`,
                401: `Неверные данные для авторизации`,
                403: `Возврат доступен для ролей admin,partner`,
            },
        });
    }
    /**
     * Добавить метод оплаты
     * Создаем платеж с суммой 1р с подтверждением и возвращаем ссылку для оплаты. После успешной оплаты ждем пока получим уведомление об успешной оплате сохраняем способ оплаты и отменяем платеж.
     * @returns CreatePaymentResponseDTO Ссылка для перехода на страницу оплаты ЮKassa, для ввода данных карты или выбора других способов оплаты.
     * @throws ApiError
     */
    public addPaymentMethod({
        requestBody,
    }: {
        requestBody: AddPaymentMethodDTO,
    }): CancelablePromise<CreatePaymentResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/method',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка создания платежа`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Выбрать метод оплаты по умолчанию
     * @returns void
     * @throws ApiError
     */
    public setDefaultPaymentMethod({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/payment/method/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Срок действия карты истек.`,
                401: `Неверные данные для авторизации`,
                404: `Не найден способ оплаты`,
            },
        });
    }
    /**
     * Удалить метод оплаты
     * @returns void
     * @throws ApiError
     */
    public deletePaymentMethod({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/payment/method/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден способ оплаты`,
            },
        });
    }
    /**
     * Список методов оплаты пользователя
     * @returns PaymentMethodResponseDTO
     * @throws ApiError
     */
    public getPaymentMethodList(): CancelablePromise<Array<PaymentMethodResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/method/list',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
}
