/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateEcsConnectorDTO } from './UpdateEcsConnectorDTO';
import type { UpdateEcsParkingControllerResponseDTO } from './UpdateEcsParkingControllerResponseDTO';
export type UpdateEcsDTO = {
    name?: string;
    address?: string;
    description?: string;
    latLong?: string;
    type?: UpdateEcsDTO.type;
    isActive?: boolean;
    connectors?: Array<UpdateEcsConnectorDTO>;
    parkingController?: UpdateEcsParkingControllerResponseDTO;
};
export namespace UpdateEcsDTO {
    export enum type {
        SLOW = 'slow',
        FAST = 'fast',
    }
}

