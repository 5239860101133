/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptPushNotificationDTO } from '../models/AcceptPushNotificationDTO';
import type { DisablePushNotificationDTO } from '../models/DisablePushNotificationDTO';
import type { NotificationResponseDTO } from '../models/NotificationResponseDTO';
import type { ReadOrDeleteNotificationsDTO } from '../models/ReadOrDeleteNotificationsDTO';
import type { TestCreateNotificationDTO } from '../models/TestCreateNotificationDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NotificationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Создать тестовое уведомление
     * Позже этот запрос будет удален
     * @returns any
     * @throws ApiError
     */
    public testCreateNotification({
        requestBody,
    }: {
        requestBody: TestCreateNotificationDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notification/test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Установить статус прочитано для уведомлений
     * @returns void
     * @throws ApiError
     */
    public readNotifications({
        requestBody,
    }: {
        requestBody: ReadOrDeleteNotificationsDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/notification/read',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверные данные`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Удалить уведомления
     * @returns void
     * @throws ApiError
     */
    public deleteNotifications({
        requestBody,
    }: {
        requestBody: ReadOrDeleteNotificationsDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/notification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверные данные`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Список уведомлений
     * @returns NotificationResponseDTO Уведомления пользователя
     * @throws ApiError
     */
    public getNotificationList({
        limit = 10,
        isRead,
        isReceived,
        lastDate,
        createdAtFrom,
        createdAtTo,
    }: {
        /**
         * Если указано 0 то вернем данные без лимита
         */
        limit?: number,
        isRead?: boolean,
        /**
         * При получении списка уведомлений если значение false то для полученных данных обновляем статус isReceived на true.
         */
        isReceived?: boolean,
        /**
         * Используется для фильтрации получаемых данных. Получаем дынные где дата создания меньше указанной даты.
         */
        lastDate?: string,
        createdAtFrom?: string,
        createdAtTo?: string,
    }): CancelablePromise<Array<NotificationResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notification/list',
            query: {
                'limit': limit,
                'isRead': isRead,
                'isReceived': isReceived,
                'lastDate': lastDate,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Количество уведомлений
     * @returns number Количество пользователя
     * @throws ApiError
     */
    public getNotificationCount({
        limit = 10,
        isRead,
        isReceived,
        createdAtFrom,
        createdAtTo,
    }: {
        /**
         * Если указано 0 то вернем данные без лимита
         */
        limit?: number,
        isRead?: boolean,
        /**
         * При получении списка уведомлений если значение false то для полученных данных обновляем статус isReceived на true.
         */
        isReceived?: boolean,
        createdAtFrom?: string,
        createdAtTo?: string,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notification/count',
            query: {
                'limit': limit,
                'isRead': isRead,
                'isReceived': isReceived,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Разрешить пуш уведомления
     * @returns any
     * @throws ApiError
     */
    public acceptPushNotification({
        requestBody,
    }: {
        requestBody: AcceptPushNotificationDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notification/accept-push',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Блокировать пуш уведомления
     * @returns void
     * @throws ApiError
     */
    public disablePushNotification({
        requestBody,
    }: {
        requestBody: DisablePushNotificationDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/notification/disable-push',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
}
