/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EcsPartResponseDTO } from './EcsPartResponseDTO';
import type { EcsTransactionConnectorResponseDTO } from './EcsTransactionConnectorResponseDTO';
import type { ProfilePartResponseDTO } from './ProfilePartResponseDTO';
import type { RateResponseDTO } from './RateResponseDTO';
export type EcsTransactionResponseDTO = {
    _id: string;
    owner?: (string | ProfilePartResponseDTO);
    ecsOwner?: (string | ProfilePartResponseDTO);
    ecs: (string | EcsPartResponseDTO);
    connector: EcsTransactionConnectorResponseDTO;
    /**
     * Если нет тарифа то зарядка бесплатна
     */
    rate?: RateResponseDTO;
    amount: number;
    reserveAmount: number;
    startTransactionAmount: number;
    energy: number;
    chargingTimeHours: number;
    meterStart: number;
    meterStop: number;
    isPaymentBalance: boolean;
    status: EcsTransactionResponseDTO.status;
    startDate: string;
    stopDate: string;
};
export namespace EcsTransactionResponseDTO {
    export enum status {
        CHARGING = 'charging',
        AWAITING_PAYMENT = 'awaitingPayment',
        NOT_PAYMENT = 'notPayment',
        COMPLETED = 'completed',
    }
}

