/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ConfirmationPaymentDTO = {
    type: ConfirmationPaymentDTO.type;
    return_url?: string;
    locale?: string;
    enforce?: boolean;
};
export namespace ConfirmationPaymentDTO {
    export enum type {
        EMBEDDED = 'embedded',
        EXTERNAL = 'external',
        QR = 'qr',
        REDIRECT = 'redirect',
    }
}

