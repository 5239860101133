/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateFirmwareEcsDTO = {
    location: UpdateFirmwareEcsDTO.location;
    ecs: string;
};
export namespace UpdateFirmwareEcsDTO {
    export enum location {
        IMMEDIATE = 'Immediate',
        ON_IDLE = 'OnIdle',
    }
}

