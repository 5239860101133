/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateEcsConnectorParkingDTO } from './UpdateEcsConnectorParkingDTO';
export type UpdateEcsConnectorDTO = {
    _id: string;
    /**
     * id тарифа или пустая строка чтобы. Если нет тарифа значит зарядка бесплатная.
     */
    rate?: string;
    power?: number;
    connectorId?: number;
    connector?: UpdateEcsConnectorDTO.connector;
    status?: UpdateEcsConnectorDTO.status;
    parking?: UpdateEcsConnectorParkingDTO;
};
export namespace UpdateEcsConnectorDTO {
    export enum connector {
        NONE = 'none',
        TYPE1 = 'type1',
        TYPE2 = 'type2',
        CHADEMO = 'chademo',
        CSS_C1 = 'cssC1',
        CSS_C2 = 'cssC2',
        GBT = 'gbt',
    }
    export enum status {
        AVAILABLE = 'Available',
        OCCUPIED = 'Occupied',
        CHARGING = 'Charging',
        RESERVED = 'Reserved',
        UNAVAILABLE = 'Unavailable',
        FAULTED = 'Faulted',
    }
}

