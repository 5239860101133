/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPayoutMethodCardDTO } from './AddPayoutMethodCardDTO';
export type AddPayoutMethodDTO = {
    /**
     * Данные карты для вывода средств
     */
    card: AddPayoutMethodCardDTO;
    /**
     * Токен используемый для запроса вывода средств. Получаем через виджет юкасы при добавлении способа вывода средств карты.
     */
    payoutToken: string;
    type?: AddPayoutMethodDTO.type;
};
export namespace AddPayoutMethodDTO {
    export enum type {
        BANK_CARD = 'bank_card',
        SBP = 'sbp',
    }
}

