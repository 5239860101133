import { NavigateFunction } from "react-router-dom";
import config from "../../config";
import { AnyData, RecordStringAny } from "../types";
import { SignInEmailDTO } from "src/api/openapi/models/SignInEmailDTO";
import { UserInfoResponseDTO } from "src/api/openapi/models/UserInfoResponseDTO";

// это может не сработать если используется несколько раз для разных функций поэтому лучше создавать отдельную такую функцию
export const debounce = (() => {
  let timer = 0;
  return function (callback: () => void, ms: number) {
    clearTimeout(timer);
    timer = window.setTimeout(callback, ms);
  };
})();

export const getFileLink = (link: string): string => {
  if (!link) return "";

  return `${config.apiUrl}/file/${link}`;
};

export const getUserFullName = (
  user: Pick<UserInfoResponseDTO, "firstName" | "lastName">
): string => `${user.lastName} ${user.firstName}`;

export const getUserFirstLetterFullName = (
  user: Pick<UserInfoResponseDTO, "firstName" | "lastName">
): string =>
  `${(user.lastName[0] || "").toUpperCase()}${(
    user.firstName[0] || ""
  ).toUpperCase()}`;

export const historyBack = (navigate: NavigateFunction, link = "/") => {
  if (history.state && history.state.key) {
    navigate(-1);
  } else {
    navigate(link);
  }
};

export const isUser = (role: UserInfoResponseDTO.role): boolean =>
  role === UserInfoResponseDTO.role.USER;
export const isPartner = (role: UserInfoResponseDTO.role): boolean =>
  role === UserInfoResponseDTO.role.PARTNER;
export const isAdmin = (role: UserInfoResponseDTO.role): boolean =>
  role === SignInEmailDTO.role.ADMIN;
export const isOperator = (role: UserInfoResponseDTO.role): boolean =>
  role === SignInEmailDTO.role.OPERATOR;
export const isOperatorOrAdmin = (role: UserInfoResponseDTO.role): boolean =>
  [UserInfoResponseDTO.role.ADMIN, UserInfoResponseDTO.role.OPERATOR].includes(role);
export const isOperatorOrAdminSignIn = (role: SignInEmailDTO.role): boolean =>
  [SignInEmailDTO.role.ADMIN, SignInEmailDTO.role.OPERATOR].includes(role);

export const stopPropagationEvent = (e: Pick<Event, "stopPropagation">) => {
  e.stopPropagation();
};

export const isEqualObject = (
  obj1: RecordStringAny,
  obj2: RecordStringAny
): boolean => {
  const obj1Keys = Object.keys(obj1).sort();
  const obj2Keys = Object.keys(obj2).sort();

  if (obj1Keys.length !== obj2Keys.length) return false;
  return obj1Keys.every((key, index) => {
    const objValue1 = obj1[key];
    const objValue2 = obj2[obj2Keys[index]];
    return objValue1 === objValue2;
  });
};

export const getType = (value: AnyData): string => {
  if (Array.isArray(value)) return "array";
  if (typeof value === "string") return "string";
  if (value !== null && typeof value === "object") return "object";

  return typeof value;
};

export const formData = (data: RecordStringAny) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value instanceof File) {
      formData.append(key, value, value.name || "");
    } else {
      if (getType(value) === "object") value = JSON.stringify(value);
      formData.append(key, value);
    }
  });

  return formData;
};

export const getTextTruncate = (text: string, limit: number): string => {
  const reg = new RegExp(`(^.{${limit}}([^ ]+|\s))(.*)`);
  const truncate = text.replace(reg, "$1");

  return truncate.length < text.length ? truncate + "…" : text;
};

export const scrollNearBottom = (element: Element, subtract = 0) => {
  const diff = Math.round(element.scrollHeight - element.scrollTop);
  return diff - subtract <= element.clientHeight;
};
