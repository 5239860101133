/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ResetEcsDTO = {
    type: ResetEcsDTO.type;
    ecs: string;
};
export namespace ResetEcsDTO {
    export enum type {
        IMMEDIATE = 'Immediate',
        ON_IDLE = 'OnIdle',
    }
}

