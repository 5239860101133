/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AddOrUpdateSupportFaqDTO = {
    title: string;
    description: string;
    /**
     * Положение вопроса, сортируем от большего к меньшему
     */
    position: number;
    role?: AddOrUpdateSupportFaqDTO.role;
};
export namespace AddOrUpdateSupportFaqDTO {
    export enum role {
        NOT_AUTH = 'notAuth',
        ALL_AUTH_USER = 'allAuthUser',
        USER = 'user',
        PARTNER = 'partner',
    }
}

