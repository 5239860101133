/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminProfileResponseDTO } from '../models/AdminProfileResponseDTO';
import type { AdminUpdateProfileDTO } from '../models/AdminUpdateProfileDTO';
import type { BlockUserDTO } from '../models/BlockUserDTO';
import type { CreateAdminUserDTO } from '../models/CreateAdminUserDTO';
import type { DeclineDocumentsBusinessDTO } from '../models/DeclineDocumentsBusinessDTO';
import type { PageAdminProfileResponseDTO } from '../models/PageAdminProfileResponseDTO';
import type { UploadFileDTO } from '../models/UploadFileDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AdminUserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Создать администратора или оператора
     * @returns void
     * @throws ApiError
     */
    public createUser({
        requestBody,
    }: {
        requestBody: CreateAdminUserDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/create-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                409: `Емайл или номер телефона занят`,
            },
        });
    }
    /**
     * Принять документы партнера
     * @returns void
     * @throws ApiError
     */
    public approveDocumentsBusiness({
        formData,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        formData: UploadFileDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/{id}/approve-documents-business',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Статус не pending`,
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Отклонить документы партнера
     * @returns void
     * @throws ApiError
     */
    public declineDocumentsBusiness({
        requestBody,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        requestBody: DeclineDocumentsBusinessDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/{id}/decline-documents-business',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Статус не pending`,
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Принять подписанный контракт
     * @returns void
     * @throws ApiError
     */
    public approveSignatureDocument({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/{id}/approve-signature-document',
            path: {
                'id': id,
            },
            errors: {
                400: `Статус не pendingSignature`,
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Отклонить подписанный контракт
     * @returns void
     * @throws ApiError
     */
    public declineSignatureDocument({
        requestBody,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        requestBody: DeclineDocumentsBusinessDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/{id}/decline-signature-document',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Статус не pendingSignature`,
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Заблокировать авторизацию пользователя
     * @returns void
     * @throws ApiError
     */
    public blockUserAuth({
        requestBody,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        requestBody: BlockUserDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/{id}/block-auth',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Нельзя заблокировать себя`,
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Разблокировать авторизацию пользователя
     * @returns void
     * @throws ApiError
     */
    public unLockUserAuth({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/{id}/unlock-auth',
            path: {
                'id': id,
            },
            errors: {
                400: `Нельзя разблокировать себя`,
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Обновить профиль пользователя
     * @returns void
     * @throws ApiError
     */
    public adminUpdateProfile({
        requestBody,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        requestBody: AdminUpdateProfileDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                409: `Емайл занят`,
            },
        });
    }
    /**
     * Удалить пользователя
     * @returns any
     * @throws ApiError
     */
    public deleteUser({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Профиль пользователя
     * @returns AdminProfileResponseDTO Профиль пользователя
     * @throws ApiError
     */
    public adminGetProfile({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<AdminProfileResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Список пользователей
     * @returns PageAdminProfileResponseDTO Список пользователей
     * @throws ApiError
     */
    public getList({
        page = 1,
        limit = 10,
        search,
        role,
        status,
    }: {
        page?: number,
        limit?: number,
        /**
         * Поиск по имени, фамилии, номеру и емайлу.
         */
        search?: string,
        role?: 'user' | 'partner' | 'operator' | 'admin',
        status?: 'opening' | 'pending' | 'decline' | 'awaitingSignature' | 'pendingSignature' | 'declineSignature' | 'approve',
    }): CancelablePromise<PageAdminProfileResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/list',
            query: {
                'page': page,
                'limit': limit,
                'search': search,
                'role': role,
                'status': status,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
}
