/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EcsParkingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns any
     * @throws ApiError
     */
    public openBarrier({
        ecsId,
        connectorId,
    }: {
        /**
         * ECS Id
         */
        ecsId: string,
        /**
         * Controller Id
         */
        connectorId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/parking/open/{ecsId}/{connectorId}',
            path: {
                'ecsId': ecsId,
                'connectorId': connectorId,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public closeBarrier({
        ecsId,
        connectorId,
    }: {
        /**
         * ECS Id
         */
        ecsId: string,
        /**
         * Controller Id
         */
        connectorId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/parking/close/{ecsId}/{connectorId}',
            path: {
                'ecsId': ecsId,
                'connectorId': connectorId,
            },
        });
    }
    /**
     * @returns any File result
     * @throws ApiError
     */
    public getCamera({
        ecsId,
    }: {
        ecsId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/parking/camera/{ecsId}',
            path: {
                'ecsId': ecsId,
            },
        });
    }
}
