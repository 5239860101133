/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePaymentResponseDTO } from '../models/CreatePaymentResponseDTO';
import type { EcsTransactionResponseDTO } from '../models/EcsTransactionResponseDTO';
import type { PageEcsTransactionResponseDTO } from '../models/PageEcsTransactionResponseDTO';
import type { PaymentEcsTransactionDTO } from '../models/PaymentEcsTransactionDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EcsOcppTransactionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Оплатить транзакции зарядки
     * Оплачиваем неудачную оплату транзакции зарядки
     * @returns CreatePaymentResponseDTO Вернет данные для оплаты если транзакцию нужно оплачивать.
     * @throws ApiError
     */
    public ecsPaymentTransaction({
        requestBody,
    }: {
        requestBody: PaymentEcsTransactionDTO,
    }): CancelablePromise<CreatePaymentResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs/ocpp/transaction/payment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Запрос разрешен только для пользователей с ролью "user"`,
                404: `Не найдена неудачная транзакция`,
            },
        });
    }
    /**
     * История транзакций зарядок
     * История транзакций зарядок с пагинацией
     * @returns PageEcsTransactionResponseDTO История зарядок
     * @throws ApiError
     */
    public getEcsTransactionList({
        ecs,
        status,
        startDateFrom,
        startDateTo,
        page = 1,
        limit = 10,
    }: {
        ecs?: string,
        status?: 'charging' | 'awaitingPayment' | 'notPayment' | 'completed',
        startDateFrom?: string,
        startDateTo?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PageEcsTransactionResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/ocpp/transaction/list',
            query: {
                'ecs': ecs,
                'status': status,
                'startDateFrom': startDateFrom,
                'startDateTo': startDateTo,
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Получить транзакцию станции
     * @returns EcsTransactionResponseDTO Получить транзакцию станции.
     * @throws ApiError
     */
    public getEcsTransaction({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<EcsTransactionResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/ocpp/transaction/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найдена транзакция`,
            },
        });
    }
}
