/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EcsPartResponseDTO } from './EcsPartResponseDTO';
import type { PickTypeClass } from './PickTypeClass';
export type ReserveResponseDTO = {
    _id: string;
    owner: string;
    ecs: (string | EcsPartResponseDTO);
    ecsConnector: string;
    car: PickTypeClass;
    amount: number;
    isPaymentBalance: boolean;
    startDate: string;
    endDate: string;
    status: ReserveResponseDTO.status;
};
export namespace ReserveResponseDTO {
    export enum status {
        CANCELED = 'canceled',
        ACTIVE = 'active',
        CHARGING = 'charging',
        COMPLETED = 'completed',
    }
}

