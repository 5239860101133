/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PromoCodeResponseDTO = {
    _id: string;
    owner: string;
    name: string;
    description: string;
    /**
     * Значение скидки
     */
    value: number;
    type: PromoCodeResponseDTO.type;
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
};
export namespace PromoCodeResponseDTO {
    export enum type {
        AMOUNT = 'amount',
        PERCENT = 'percent',
    }
}

