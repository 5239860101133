import React, { FC, useEffect, useState } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "../../../common/utils";

type TextFieldSearchProps = TextFieldProps & {
  value: string;
  minLength?: number;
  setValue: (value: string) => void;
};

const TextFieldSearch: FC<TextFieldSearchProps> = ({
  value,
  setValue,
  minLength = 0,
  ...props
}) => {
  const { t } = useTranslation();
  const [valSearch, setValSearch] = useState(value);

  const handleSearch = (val: string) => {
    setValSearch(val);
    debounce(() => {
      if (val.length === 0) return setValue("");
      if (val.length > minLength) setValue(val);
    }, 500);
  };

  useEffect(() => {
    if (value.length === 0 && valSearch) setValSearch("");
  }, [value]);

  return (
    <TextField
      size="small"
      fullWidth
      placeholder={t("common.search")}
      {...props}
      value={valSearch}
      onChange={(e) => handleSearch(e.target.value)}
      InputProps={{
        endAdornment: <SearchIcon />,
      }}
    />
  );
};

export default TextFieldSearch;
