/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SupportSendEmailAdminDTO } from '../models/SupportSendEmailAdminDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SupportService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Отправить емайл админу
     * @returns any
     * @throws ApiError
     */
    public supportSendEmailAdmin({
        requestBody,
    }: {
        requestBody: SupportSendEmailAdminDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/support/send-email-admin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
