/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EcsPartResponseDTO = {
    _id: string;
    name: string;
    type: EcsPartResponseDTO.type;
};
export namespace EcsPartResponseDTO {
    export enum type {
        SLOW = 'slow',
        FAST = 'fast',
    }
}

