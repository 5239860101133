import { PaletteMode } from "@mui/material";
import { Components, Theme, createTheme } from "@mui/material/styles";

const getThemeComponents = (
  mode: string
): Components<Omit<Theme, "components">> => {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          "&.avatar-menu": {
            backgroundColor: mode === "light" ? "#00b398" : "#121212",
            color: "#fff",
            ".MuiListItemIcon-root": {
              color: "inherit",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: mode === "light" ? "#00b398" : "#121212",
          color: "#fff",
          ".MuiList-root .Mui-selected": {
            backgroundColor:
              mode === "light" ? "rgb(71 237 212 / 52%)" : "#262f37",
          },
          ".MuiListItemIcon-root": {
            color: "inherit",
          },
        },
      },
    },
  };
};

export const customTheme = (mode: string) => {
  return createTheme({
    palette: {
      background: {
        default: mode === "light" ? "#ededed" : "#121212",
      },
      primary: {
        main: mode === "light" ? "#00b398" : "rgb(144, 202, 249)",
        contrastText: mode === "light" ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
      mode: mode as PaletteMode,
    },
    components: getThemeComponents(mode),
  });
};
