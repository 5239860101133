/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddOrUpdateSupportFaqDTO } from '../models/AddOrUpdateSupportFaqDTO';
import type { SupportFaqResponseDTO } from '../models/SupportFaqResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SupportFaqService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Добавить часто задаваемый вопрос
     * @returns SupportFaqResponseDTO Созданный вопрос
     * @throws ApiError
     */
    public supportFaqCreate({
        requestBody,
    }: {
        requestBody: AddOrUpdateSupportFaqDTO,
    }): CancelablePromise<SupportFaqResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/support/faq',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Обновить часто задаваемый вопрос
     * @returns SupportFaqResponseDTO Обновленный вопрос
     * @throws ApiError
     */
    public supportFaqUpdate({
        requestBody,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        requestBody: AddOrUpdateSupportFaqDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<SupportFaqResponseDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/support/faq/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Удалить часто задаваемый вопрос
     * @returns void
     * @throws ApiError
     */
    public supportFaqDelete({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/support/faq/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Список часто задаваемых вопросов
     * Работает с авторизацией и без.
     * @returns SupportFaqResponseDTO Список часто задаваемых вопросов
     * @throws ApiError
     */
    public getSupportFaqList(): CancelablePromise<Array<SupportFaqResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/support/faq/list',
        });
    }
}
