/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SendDocumentsBusinessResponseDTO } from './SendDocumentsBusinessResponseDTO';
import type { UserAdminPanelInfoDTO } from './UserAdminPanelInfoDTO';
import type { UserSettingsResponseDTO } from './UserSettingsResponseDTO';
export type UserInfoResponseDTO = {
    _id: string;
    phone: string;
    email: string;
    firstName: string;
    lastName: string;
    avatar: string;
    theme: UserInfoResponseDTO.theme;
    status: UserInfoResponseDTO.status;
    referrer?: string;
    referralCode: string;
    settings: UserSettingsResponseDTO;
    /**
     * Есть только для роли partner
     */
    balancePartner?: number;
    balance: number;
    /**
     * Есть только если передать в запрос isAdminPanel=true
     */
    adminPanelInfo?: UserAdminPanelInfoDTO;
    documentsBusiness?: SendDocumentsBusinessResponseDTO;
    role: UserInfoResponseDTO.role;
    /**
     * Есть только для роли user. Активные транзакции зарядки пользователя.
     */
    activeEcsTransactions?: Array<string>;
};
export namespace UserInfoResponseDTO {
    export enum theme {
        SYSTEM = 'system',
        LIGHT = 'light',
        DARK = 'dark',
    }
    export enum status {
        OPENING = 'opening',
        PENDING = 'pending',
        DECLINE = 'decline',
        AWAITING_SIGNATURE = 'awaitingSignature',
        PENDING_SIGNATURE = 'pendingSignature',
        DECLINE_SIGNATURE = 'declineSignature',
        APPROVE = 'approve',
    }
    export enum role {
        USER = 'user',
        PARTNER = 'partner',
        OPERATOR = 'operator',
        ADMIN = 'admin',
    }
}

