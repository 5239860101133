/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AddSupportChatMessageDTO = {
    /**
     * Если не указано то автоматически будет создано новое обращение в поддержку
     */
    room?: string;
    text: string;
    /**
     * Путь загруженного файла
     */
    filePath?: string;
    fileOriginalname?: string;
    /**
     * Обязателен если указан filePath
     */
    fileType?: AddSupportChatMessageDTO.fileType;
};
export namespace AddSupportChatMessageDTO {
    /**
     * Обязателен если указан filePath
     */
    export enum fileType {
        IMAGE = 'image',
        DOCUMENT = 'document',
    }
}

