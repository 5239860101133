import { UserInfoResponseDTO } from "src/api/openapi/models/UserInfoResponseDTO";
import Context from "./context";

export const initialUserState: UserInfoResponseDTO = {
  _id: "",
  referralCode: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  avatar: "",
  balancePartner: 0,
  role: UserInfoResponseDTO.role.USER,
  adminPanelInfo: {
    webTheme: UserInfoResponseDTO.theme.LIGHT,
    acceptedPrivacyPolicy: false,
  },
  status: UserInfoResponseDTO.status.PENDING,
  theme: UserInfoResponseDTO.theme.LIGHT,
  settings: {
    notifications: {
      sendPushAll: false,
      sendEmailAll: false,
    },
  },
  balance: 0,
};

const UserContext = new Context(initialUserState);

export default UserContext;
