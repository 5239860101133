/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EcsResponseDTO } from '../models/EcsResponseDTO';
import type { EcsTransactionResponseDTO } from '../models/EcsTransactionResponseDTO';
import type { NotificationResponseDTO } from '../models/NotificationResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SseService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Уведомления пользователя.
     * Каждый раз при создании нового уведомления отправит пользователю это уведомление.
     * @returns NotificationResponseDTO Данные уведомления.
     * @throws ApiError
     */
    public sseNotification(): CancelablePromise<NotificationResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sse/notification',
            errors: {
                401: `Неверные данные для авторизации. Передайте токен авторизации через GET запрос ?token={authToken}`,
            },
        });
    }
    /**
     * Получить обновленные данные станции
     * При обновлении статуса коннектора станции будут отправлены обновленные данные станции
     * @returns EcsResponseDTO Данные станции.
     * @throws ApiError
     */
    public ecsUpdate({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<EcsResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sse/ecs-update/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации. Передайте токен авторизации через GET запрос ?token={authToken}`,
            },
        });
    }
    /**
     * Получить обновленные данные зарядки
     * При обновлении данных ecsTransaction будут отправлены обновленные данные.
     * @returns EcsTransactionResponseDTO Данные транзакции зарядки.
     * @throws ApiError
     */
    public ecsTransactionCharging(): CancelablePromise<EcsTransactionResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sse/ecs-transaction-charging',
            errors: {
                401: `Неверные данные для авторизации. Передайте токен авторизации через GET запрос ?token={authToken}`,
            },
        });
    }
}
