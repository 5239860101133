import { useNavigate } from "react-router-dom";
import UserContext from "../../store/contexts/user-context";
import { tokenHook } from "./tokenHook";
import { useTranslation } from "react-i18next";
import SnackbarContext from "../../store/contexts/snackbar-context";
import {
  getErrorCodeOrMessageError,
  isAdmin,
  isOperatorOrAdminSignIn,
} from "../utils";
import { useState } from "react";
import appClient from "../../api/appClient";
import {
  ForgotPasswordDTO,
  ResetPasswordDTO,
  SignInEmailDTO,
  SignUpPartnerDTO,
} from "../../api/openapi";

export const authSignInEmailHook = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setTokens } = tokenHook();
  const { setData: setSnackbar } = SnackbarContext.useContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const signInEmail = (data: SignInEmailDTO) => {
    setIsLoading(true);

    appClient.auth
      .signInEmail({ requestBody: data })
      .then((res) => {
        setTokens(
          res,
          isOperatorOrAdminSignIn(data.role || SignInEmailDTO.role.PARTNER)
        );
        navigate("/");
      })
      .catch((error) => {
        const errorMessage = getErrorCodeOrMessageError(error, t);

        if (errorMessage) {
          setSnackbar({
            open: true,
            message: errorMessage,
            type: "error",
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    signInEmail,
  };
};

export const authLogoutHook = () => {
  const navigate = useNavigate();
  const { removeTokens } = tokenHook();
  const {
    data: { role },
  } = UserContext.useContext();

  const authLogout = () => {
    removeTokens();
    navigate(isAdmin(role) ? "/login/admin" : "/login");
  };

  return {
    authLogout,
  };
};

export const forgotPasswordHook = () => {
  const { t } = useTranslation();
  const { setData: setSnackbar } = SnackbarContext.useContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const forgotPassword = (data: ForgotPasswordDTO) => {
    setIsLoading(true);

    appClient.auth
      .forgotPassword({ requestBody: data })
      .then(() => {
        setIsSuccess(true);
      })
      .catch((error) => {
        const errorMessage = getErrorCodeOrMessageError(error, t);

        if (errorMessage) {
          setSnackbar({
            open: true,
            message: errorMessage,
            type: "error",
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    forgotPassword,
    isSuccess,
  };
};

export const resetPasswordHook = () => {
  const { t } = useTranslation();
  const { setData: setSnackbar } = SnackbarContext.useContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const resetPassword = (data: ResetPasswordDTO) => {
    setIsLoading(true);

    appClient.auth
      .resetPassword({ requestBody: data })
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        const errorMessage = getErrorCodeOrMessageError(error, t);

        if (errorMessage) {
          setSnackbar({
            open: true,
            message: errorMessage,
            type: "error",
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    resetPassword,
  };
};

export const signUpPartnerHook = () => {
  const { t } = useTranslation();
  const { setData: setSnackbar } = SnackbarContext.useContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setTokens } = tokenHook();
  const navigate = useNavigate();

  const signUpPartner = (data: SignUpPartnerDTO) => {
    setIsLoading(true);

    appClient.auth
      .signUpPartner({ requestBody: data })
      .then((data) => {
        setSnackbar({
          open: true,
          message: t("pages.signUpPartner.successRegistration"),
          type: "success",
        });
        setTokens(data, isOperatorOrAdminSignIn(SignInEmailDTO.role.PARTNER));
        navigate("/");
      })
      .catch((error) => {
        const errorMessage = getErrorCodeOrMessageError(error, t);

        if (errorMessage) {
          setSnackbar({
            open: true,
            message: errorMessage,
            type: "error",
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    signUpPartner,
  };
};
