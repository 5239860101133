import React, { FC } from "react";
import UserContext, { initialUserState } from "../contexts/user-context";
import Provider, { ProviderProps } from "./provider";

const UserProvider: FC<ProviderProps> = ({ children }) => (
  <Provider initialState={initialUserState} ContextComponent={UserContext}>
    {children}
  </Provider>
);

export default UserProvider;
