/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfilePartResponseDTO } from './ProfilePartResponseDTO';
export type SupportChatRoomMessageResponseDTO = {
    _id: string;
    owner?: ProfilePartResponseDTO;
    room: string;
    text: string;
    filePath?: string;
    fileOriginalname?: string;
    fileType?: SupportChatRoomMessageResponseDTO.fileType;
    isRead: boolean;
    createdAt: string;
    updatedAt: string;
};
export namespace SupportChatRoomMessageResponseDTO {
    export enum fileType {
        IMAGE = 'image',
        DOCUMENT = 'document',
    }
}

