/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePromoCodeDTO } from '../models/CreatePromoCodeDTO';
import type { PagePromoCodeResponseDTO } from '../models/PagePromoCodeResponseDTO';
import type { PromoCodeResponseDTO } from '../models/PromoCodeResponseDTO';
import type { UpdatePromoCodeDTO } from '../models/UpdatePromoCodeDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PromoCodeService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Добавить промокод
     * @returns any
     * @throws ApiError
     */
    public createPromoCode({
        requestBody,
    }: {
        requestBody: CreatePromoCodeDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/promo-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Обновить промокод
     * @returns void
     * @throws ApiError
     */
    public updatePromoCode({
        requestBody,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        requestBody: UpdatePromoCodeDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/promo-code/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Удалить промокод
     * @returns void
     * @throws ApiError
     */
    public deletePromoCode({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/promo-code/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Промокод
     * @returns PromoCodeResponseDTO Промокод пользователя
     * @throws ApiError
     */
    public getPromoCode({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<PromoCodeResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/promo-code/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Промокод не найдена`,
            },
        });
    }
    /**
     * Список промокодов
     * @returns PagePromoCodeResponseDTO Промокоды пользователя
     * @throws ApiError
     */
    public getPromoCodeList({
        page = 1,
        limit = 10,
    }: {
        page?: number,
        limit?: number,
    }): CancelablePromise<PagePromoCodeResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/promo-code/list',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
}
