import React, { useEffect, useState, lazy } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../store/contexts/user-context";
import { Loader } from "../components/UI";
import { authLogoutHook } from "../common/hooks/authHook";
import { tokenHook } from "../common/hooks/tokenHook";
import appClient from "src/api/appClient";

const PrivateRouterLayout = lazy(() => import("./PrivateRouterLayout"));
const PrivacyPolicy = lazy(() => import("../pages/private/PrivacyPolicy"));

const PrivateRouter = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { data: user, setData } = UserContext.useContext();
  const { authLogout } = authLogoutHook();
  const { getAccessToken } = tokenHook();

  const authUserInfo = () => {
    appClient.auth
      .getUserInfo({ isAdminPanel: true })
      .then((data) => {
        setData(data);
      })
      .catch(() => {
        authLogout();
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!getAccessToken()) {
      navigate("/login");
    } else {
      authUserInfo();
    }
  }, []);

  if (isLoading) return <Loader isMinHeight />;

  if (user.adminPanelInfo && !user.adminPanelInfo.acceptedPrivacyPolicy)
    return <PrivacyPolicy />;

  return <PrivateRouterLayout />;
};

export default PrivateRouter;
