/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NotificationResponseDTO = {
    _id: string;
    title: string;
    body: string;
    type: NotificationResponseDTO.type;
    isRead: boolean;
    isReceived: boolean;
    metadata: Record<string, any>;
    createdAt: string;
    updatedAt: string;
};
export namespace NotificationResponseDTO {
    export enum type {
        TEST = 'test',
        PAYMENT_TRANSACTION_PAYMENT = 'paymentTransactionPayment',
        PAYMENT_TRANSACTION_CANCEL = 'paymentTransactionCancel',
        ECS_START_TRANSACTION = 'ecsStartTransaction',
        ECS_STOP_TRANSACTION = 'ecsStopTransaction',
        ECS_PARKING_AUTO_CLOSING_ = 'ecsParkingAutoClosing ',
        RESERVE_CHARGING_SOON = 'reserveChargingSoon',
        RESERVE_CHARGING_LATE = 'reserveChargingLate',
        RESERVE_CANCELED = 'reserveCanceled',
        SUPPORT_CHAT_NEW_MESSAGE = 'supportChatNewMessage',
        USER_SEND_DOCUMENTS_BUSINESS = 'userSendDocumentsBusiness',
        USER_APPROVE_DOCUMENTS_BUSINESS = 'userApproveDocumentsBusiness',
        USER_DECLINE_DOCUMENTS_BUSINESS = 'userDeclineDocumentsBusiness',
        USER_SEND_SIGNATURE_DOCUMENT = 'userSendSignatureDocument',
        USER_APPROVE_SIGNATURE_DOCUMENT = 'userApproveSignatureDocument',
        USER_DECLINE_SIGNATURE_DOCUMENT = 'userDeclineSignatureDocument',
    }
}

