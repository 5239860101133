/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SignInPhoneDTO = {
    phone: string;
    code: string;
    role?: SignInPhoneDTO.role;
};
export namespace SignInPhoneDTO {
    export enum role {
        USER = 'user',
        PARTNER = 'partner',
        OPERATOR = 'operator',
        ADMIN = 'admin',
    }
}

