import React from "react";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { UseFormRegisterReturn, ControllerRenderProps } from "react-hook-form";
import { AnyData, SelectItem } from "../../../common/types";

interface MultipleSelectFormProps {
  label: string;
  items: SelectItem[];
  defaultValue?: SelectItem["value"][];
  formControl?: FormControlProps;
  formRegister?: ControllerRenderProps<AnyData, AnyData> | UseFormRegisterReturn;
  errorMessage?: string;
}

const MultipleSelectForm: React.FC<MultipleSelectFormProps> = ({
  formControl,
  formRegister,
  errorMessage,
  defaultValue = [],
  label,
  items,
}) => {
  return (
    <FormControl {...formControl}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        multiple
        notched
        displayEmpty
        defaultValue={defaultValue}
        {...formRegister}
        label={label}
      >
        {items.map((item, i) => (
          <MenuItem key={i} {...item}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default MultipleSelectForm;
