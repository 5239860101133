/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateAdminUserDTO = {
    phone?: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    role: CreateAdminUserDTO.role;
};
export namespace CreateAdminUserDTO {
    export enum role {
        OPERATOR = 'operator',
        ADMIN = 'admin',
    }
}

