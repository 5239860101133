/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EcsConnectorParkingResponseDTO } from './EcsConnectorParkingResponseDTO';
import type { RateResponseDTO } from './RateResponseDTO';
export type EcsConnectorResponseDTO = {
    _id: string;
    /**
     * Если нет тарифа то зарядка бесплатна
     */
    rate?: RateResponseDTO;
    connectorId: number;
    power: number;
    connector: EcsConnectorResponseDTO.connector;
    status: EcsConnectorResponseDTO.status;
    parking?: EcsConnectorParkingResponseDTO;
};
export namespace EcsConnectorResponseDTO {
    export enum connector {
        NONE = 'none',
        TYPE1 = 'type1',
        TYPE2 = 'type2',
        CHADEMO = 'chademo',
        CSS_C1 = 'cssC1',
        CSS_C2 = 'cssC2',
        GBT = 'gbt',
    }
    export enum status {
        AVAILABLE = 'Available',
        OCCUPIED = 'Occupied',
        CHARGING = 'Charging',
        RESERVED = 'Reserved',
        UNAVAILABLE = 'Unavailable',
        FAULTED = 'Faulted',
    }
}

