/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockUserDTO } from '../models/BlockUserDTO';
import type { ChangeAdminPanelInfoDTO } from '../models/ChangeAdminPanelInfoDTO';
import type { ChangeAvatarDTO } from '../models/ChangeAvatarDTO';
import type { ChangePasswordDTO } from '../models/ChangePasswordDTO';
import type { ChangePhoneDTO } from '../models/ChangePhoneDTO';
import type { ChangeReferralCodeDTO } from '../models/ChangeReferralCodeDTO';
import type { ChangeSettingsDTO } from '../models/ChangeSettingsDTO';
import type { ChangeThemeDTO } from '../models/ChangeThemeDTO';
import type { MyProfileResponseDTO } from '../models/MyProfileResponseDTO';
import type { PageUserReferralResponseDTO } from '../models/PageUserReferralResponseDTO';
import type { SendDocumentsBusinessDTO } from '../models/SendDocumentsBusinessDTO';
import type { SendPhoneCodeDTO } from '../models/SendPhoneCodeDTO';
import type { SendPhoneCodeResponseDTO } from '../models/SendPhoneCodeResponseDTO';
import type { UpdateProfileDTO } from '../models/UpdateProfileDTO';
import type { UploadFileDTO } from '../models/UploadFileDTO';
import type { UserBalanceResponseDTO } from '../models/UserBalanceResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Смена номера телефона отправить код подтверждения
     * @returns SendPhoneCodeResponseDTO Время жизни кода
     * @throws ApiError
     */
    public changePhoneSendCode({
        requestBody,
    }: {
        requestBody: SendPhoneCodeDTO,
    }): CancelablePromise<SendPhoneCodeResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/change-phone-send-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Лимит запросов с 1 ip`,
                401: `Неверные данные для авторизации`,
                409: `Номер телефона занят`,
            },
        });
    }
    /**
     * Стать партнером
     * @returns any Ссылка для сброса пароля отправлена на емайл
     * @throws ApiError
     */
    public becomePartner(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/become-partner',
            errors: {
                400: `Нет емайла, является партнером`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Партнер отправить документы
     * Отправить необходимые документы для подтверждения администратором.
     * @returns any
     * @throws ApiError
     */
    public sendDocumentsBusiness({
        formData,
    }: {
        formData: SendDocumentsBusinessDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/send-documents-business',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Не верные данные файл отсутствует. Документы уже загружены и статус не opening,decline.`,
                401: `Неверные данные для авторизации`,
                403: `Только пользователь с ролью partner может отправить документы`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Отправить подписанный документ
     * @returns void
     * @throws ApiError
     */
    public sendSignatureDocument({
        formData,
    }: {
        formData: UploadFileDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/send-signature-document',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Статус не awaitingSignature,declineSignature`,
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Заблокировать пользователя
     * @returns void
     * @throws ApiError
     */
    public blockUser({
        requestBody,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        requestBody: BlockUserDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/{id}/block',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Удалить блокировку пользователя
     * @returns void
     * @throws ApiError
     */
    public unLockUser({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/{id}/unlock',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Обновить аватар
     * @returns string Ссылка на аватар
     * @throws ApiError
     */
    public changeAvatar({
        formData,
    }: {
        formData: ChangeAvatarDTO,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/avatar',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Обновить профиль
     * @returns void
     * @throws ApiError
     */
    public updateProfile({
        requestBody,
    }: {
        requestBody: UpdateProfileDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Емайл обязателен, если есть роль админа или партнера`,
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
                409: `Емайл занят`,
            },
        });
    }
    /**
     * Мой профиль
     * @returns MyProfileResponseDTO Мой профиль
     * @throws ApiError
     */
    public getProfile(): CancelablePromise<MyProfileResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user',
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Смена номера телефона с кодом подтверждения
     * @returns any Номер телефона изменен
     * @throws ApiError
     */
    public changePhone({
        requestBody,
    }: {
        requestBody: ChangePhoneDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/change-phone',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверные данные, время жизни кода истекло, превышено количество попыток ввода кода`,
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Обновить пароль
     * @returns any
     * @throws ApiError
     */
    public changePassword({
        requestBody,
    }: {
        requestBody: ChangePasswordDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/change-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Обновить информацию админ панели
     * @returns any
     * @throws ApiError
     */
    public changeAdminPanelInfo({
        requestBody,
    }: {
        requestBody: ChangeAdminPanelInfoDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/change-admin-panel-info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Обновить настройки
     * @returns any
     * @throws ApiError
     */
    public changeUserSettings({
        requestBody,
    }: {
        requestBody: ChangeSettingsDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/change-settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Мой баланс
     * @returns UserBalanceResponseDTO Мой баланс
     * @throws ApiError
     */
    public getBalance(): CancelablePromise<UserBalanceResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/balance',
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден пользователь`,
            },
        });
    }
    /**
     * Обновить тему
     * @returns any
     * @throws ApiError
     */
    public changeTheme({
        requestBody,
    }: {
        requestBody: ChangeThemeDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/changeTheme',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Проверить реферальный код
     * @returns boolean Статус занятости реферального кода
     * @throws ApiError
     */
    public checkReferralCodeUniq({
        requestBody,
    }: {
        requestBody: ChangeReferralCodeDTO,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/check-referral-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Обновить реферальный код
     * @returns any Код обновлен
     * @throws ApiError
     */
    public changeUserReferralCode({
        requestBody,
    }: {
        requestBody: ChangeReferralCodeDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/change-referral-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                409: `Реферальный код занят`,
            },
        });
    }
    /**
     * Список реферальных пользователей
     * @returns PageUserReferralResponseDTO Список реферальных пользователей
     * @throws ApiError
     */
    public getReferralList({
        page = 1,
        limit = 10,
    }: {
        page?: number,
        limit?: number,
    }): CancelablePromise<PageUserReferralResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/referrer/list',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
}
