/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddSupportChatMessageDTO } from '../models/AddSupportChatMessageDTO';
import type { ReadSupportChatMessageDTO } from '../models/ReadSupportChatMessageDTO';
import type { SupportChatRoomMessageResponseDTO } from '../models/SupportChatRoomMessageResponseDTO';
import type { SupportChatRoomResponseDTO } from '../models/SupportChatRoomResponseDTO';
import type { SupportChatUploadFileDTO } from '../models/SupportChatUploadFileDTO';
import type { SupportChatUploadFileResponseDTO } from '../models/SupportChatUploadFileResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SupportChatService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Создать сообщение
     * @returns SupportChatRoomMessageResponseDTO Созданное сообщение
     * @throws ApiError
     */
    public supportChatCreateMessage({
        requestBody,
    }: {
        requestBody: AddSupportChatMessageDTO,
    }): CancelablePromise<SupportChatRoomMessageResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/support/chat/create-message',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверные данные`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Загрузка файла для чата
     * @returns SupportChatUploadFileResponseDTO Данные для создания сообщения с файлом
     * @throws ApiError
     */
    public supportChatUploadFile({
        formData,
    }: {
        formData: SupportChatUploadFileDTO,
    }): CancelablePromise<SupportChatUploadFileResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/support/chat/file',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Неверные данные`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Установить статус прочитано
     * @returns string Прочитанные сообщения
     * @throws ApiError
     */
    public supportChatReadMessages({
        requestBody,
    }: {
        requestBody: ReadSupportChatMessageDTO,
    }): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/support/chat/read-messages',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверные данные`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Архивировать или разархивировать обращение
     * @returns SupportChatRoomResponseDTO Обращение
     * @throws ApiError
     */
    public supportChatRoomArchivedOrUnArchived({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<SupportChatRoomResponseDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/support/chat/room/archived-or-un-archived/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав. Только оператор может выполнить этот запрос`,
                404: `Не найдено обращение`,
            },
        });
    }
    /**
     * Назначить себе это обращение
     * @returns SupportChatRoomResponseDTO Обращение
     * @throws ApiError
     */
    public supportChatRoomAssignMe({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<SupportChatRoomResponseDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/support/chat/room/assign-me/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав. Только оператор может выполнить этот запрос`,
                404: `Не найдено обращение`,
            },
        });
    }
    /**
     * Список обращений
     * @returns SupportChatRoomResponseDTO Обращения пользователя
     * @throws ApiError
     */
    public getSupportChatRoomList({
        limit = 10,
        isArchived,
        lastDate,
        createdAtFrom,
        createdAtTo,
    }: {
        /**
         * Если указано 0 то вернем данные без лимита
         */
        limit?: number,
        isArchived?: boolean,
        /**
         * Используется для фильтрации получаемых данных. Получаем дынные где дата последней активности меньше указанной даты.
         */
        lastDate?: string,
        createdAtFrom?: string,
        createdAtTo?: string,
    }): CancelablePromise<Array<SupportChatRoomResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/support/chat/room/list',
            query: {
                'limit': limit,
                'isArchived': isArchived,
                'lastDate': lastDate,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Мое Обращение
     * @returns SupportChatRoomResponseDTO Обращение
     * @throws ApiError
     */
    public getSupportChatMyRoom(): CancelablePromise<SupportChatRoomResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/support/chat/room/my',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Обращение
     * @returns SupportChatRoomResponseDTO Обращение
     * @throws ApiError
     */
    public getSupportChatRoom({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<SupportChatRoomResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/support/chat/room/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найдено обращение`,
            },
        });
    }
    /**
     * Список сообщений обращения
     * @returns SupportChatRoomMessageResponseDTO Список сообщений обращения
     * @throws ApiError
     */
    public getSupportChatRoomMessageList({
        room,
        limit = 10,
        startId,
    }: {
        room: string,
        /**
         * Если указано 0 то вернем данные без лимита
         */
        limit?: number,
        /**
         * Используется для фильтрации получаемых данных. Получаем дынные где дата создания меньше указанной даты.
         */
        startId?: string,
    }): CancelablePromise<Array<SupportChatRoomMessageResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/support/chat/room/message/list',
            query: {
                'limit': limit,
                'startId': startId,
                'room': room,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
}
