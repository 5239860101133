/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DashboardStatisticResponseDTO = {
    all: number;
    days30: number;
    type: DashboardStatisticResponseDTO.type;
};
export namespace DashboardStatisticResponseDTO {
    export enum type {
        USER = 'user',
        PARTNER = 'partner',
        ECS = 'ecs',
        TRANSACTION = 'transaction',
        SUPPORT_REQUESTS = 'supportRequests',
        ACTIVE_SUPPORT_REQUESTS = 'activeSupportRequests',
    }
}

