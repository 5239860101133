/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EcsConnectorResponseDTO } from './EcsConnectorResponseDTO';
import type { EcsGeometryDTO } from './EcsGeometryDTO';
import type { EcsParkingControllerResponseDTO } from './EcsParkingControllerResponseDTO';
import type { ProfilePartResponseDTO } from './ProfilePartResponseDTO';
export type EcsResponseDTO = {
    _id: string;
    owner?: (string | ProfilePartResponseDTO);
    name: string;
    uniqueName: string;
    address: string;
    description: string;
    location: EcsGeometryDTO;
    connectors: Array<EcsConnectorResponseDTO>;
    parkingController?: EcsParkingControllerResponseDTO;
    type: EcsResponseDTO.type;
    isActive: boolean;
    isOcppConnect: boolean;
    isFavorite: boolean;
    createdAt: string;
    updatedAt: string;
};
export namespace EcsResponseDTO {
    export enum type {
        SLOW = 'slow',
        FAST = 'fast',
    }
}

