import { YookassaPayoutsDataWidget } from "../../common/types/yookassaWidget";
import Context from "./context";

export interface YookassaWidgetState {
  payoutsData?: YookassaPayoutsDataWidget;
}

export const initialState: YookassaWidgetState = {
  payoutsData: undefined,
};

const YookassaWidgetContext = new Context(initialState);

export default YookassaWidgetContext;
