/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SignInEmailDTO = {
    email: string;
    password: string;
    role?: SignInEmailDTO.role;
};
export namespace SignInEmailDTO {
    export enum role {
        PARTNER = 'partner',
        ADMIN = 'admin',
        OPERATOR = 'operator',
    }
}

