import React, { FC } from "react";
import YookassaWidgetContext, {
  initialState,
} from "../contexts/yookassaWidget-context";
import Provider, { ProviderProps } from "./provider";

const YookassaWidgetProvider: FC<ProviderProps> = ({ children }) => (
  <Provider
    initialState={initialState}
    ContextComponent={YookassaWidgetContext}
  >
    {children}
  </Provider>
);

export default YookassaWidgetProvider;
