/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrUpdateRateDTO } from '../models/CreateOrUpdateRateDTO';
import type { PageRateResponseDTO } from '../models/PageRateResponseDTO';
import type { RateResponseDTO } from '../models/RateResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EcsRateService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Создать тариф
     * Создание тарифа для коннектора станции
     * @returns RateResponseDTO Тариф пользователя
     * @throws ApiError
     */
    public createRate({
        requestBody,
    }: {
        requestBody: CreateOrUpdateRateDTO,
    }): CancelablePromise<RateResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ecs/rate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Обновить тариф
     * @returns void
     * @throws ApiError
     */
    public updateRate({
        requestBody,
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        requestBody: CreateOrUpdateRateDTO,
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/ecs/rate/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Удалить тариф
     * @returns void
     * @throws ApiError
     */
    public deleteRate({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ecs/rate/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                403: `Нет прав`,
            },
        });
    }
    /**
     * Тариф
     * @returns RateResponseDTO Тариф пользователя
     * @throws ApiError
     */
    public getRate({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<RateResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/rate/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Тариф не найден`,
            },
        });
    }
    /**
     * Список тарифов
     * @returns PageRateResponseDTO Список тарифов пользователя
     * @throws ApiError
     */
    public getRateList({
        page = 1,
        limit = 10,
        isCount = true,
        search,
        owner,
    }: {
        page?: number,
        limit?: number,
        /**
         * Если он активно возвращает количество записей.
         */
        isCount?: boolean,
        /**
         * Поиск по имени тарифа
         */
        search?: string,
        /**
         * Для пользователей с ролью админ чтобы получить тарифы пользователя в станции.
         */
        owner?: string,
    }): CancelablePromise<PageRateResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ecs/rate/list',
            query: {
                'page': page,
                'limit': limit,
                'isCount': isCount,
                'search': search,
                'owner': owner,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
}
