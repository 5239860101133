import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { dateLocales } from "../constants/dateLocale";
import { SupportedLocales } from "../../i18n";
dayjs.extend(utc);

export const dateFormat = "DD-MM-YYYY";
export const dateTimeFormat = "DD-MM-YYYY HH:mm";

export const newDate = (data?: dayjs.ConfigType): dayjs.Dayjs => dayjs(data);

export const dateToString = (
  date: dayjs.ConfigType,
  format: string = dateFormat
): string => dayjs(date).format(format);

export const dateTimeToString = (
  date: dayjs.ConfigType,
  format: string = dateTimeFormat
): string => dayjs(date).format(format);

export const numberToHourAndMinute = (time: number): string =>
  dayjs.utc(time * 3600 * 1000).format("HH:mm");

export const addDateMs = (
  date: dayjs.Dayjs,
  amount: number,
  unit: dayjs.ManipulateType
) => date.add(amount, unit);

export const subtractDateMs = (
  date: dayjs.Dayjs,
  amount: number,
  unit: dayjs.ManipulateType
) => date.subtract(amount, unit);

export const getDateLocale = (language: string | SupportedLocales) =>
  dateLocales[language as SupportedLocales] || dateLocales.enUS;
