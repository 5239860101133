import React, { FC } from "react";
import SnackbarContext, { initialSnackbarState } from "../contexts/snackbar-context";
import Provider, { ProviderProps } from "./provider";

const SnackbarProvider: FC<ProviderProps> = ({ children }) => (
  <Provider initialState={initialSnackbarState} ContextComponent={SnackbarContext}>
    {children}
  </Provider>
);

export default SnackbarProvider;
