import { SupportedLocales } from "../../i18n";
import { AnyData } from "../types";

export const dateLocales: {
  [key in SupportedLocales]?: {
    key: string;
    import: Promise<AnyData>;
  };
} = {
  ruRU: {
    import: import("dayjs/locale/ru"),
    key: "ru",
  },
  enUS: {
    import: import("dayjs/locale/en"),
    key: "en",
  },
};
