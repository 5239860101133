import { Box } from "@mui/material";
import React from "react";

interface CustomTabPanelProps extends React.PropsWithChildren {
  activeTab: number | string;
  value: number | string;
  details?: Record<string, string | number | boolean>;
}

const CustomTabPanel: React.FC<CustomTabPanelProps> = ({
  value,
  activeTab,
  children,
  details
}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== activeTab}
      {...details}
    >
      {children}
    </Box>
  );
};

export default CustomTabPanel;
