/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPayoutMethodDTO } from '../models/AddPayoutMethodDTO';
import type { CreatePayoutDTO } from '../models/CreatePayoutDTO';
import type { PagePayoutResponseDTO } from '../models/PagePayoutResponseDTO';
import type { PayoutMethodResponseDTO } from '../models/PayoutMethodResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PayoutService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Вывод средств
     * Вывод средств на сохраненный метод оплаты по умолчанию или выбранный метод.
     * @returns any
     * @throws ApiError
     */
    public createPayout({
        requestBody,
    }: {
        requestBody: CreatePayoutDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payout',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка вывода средств, минимальная или максимальная сумма для вывода неверна, сумма вывода больше баланса.`,
                401: `Неверные данные для авторизации`,
                404: `Нет пользователя, нет данных метода вывода средств.`,
            },
        });
    }
    /**
     * Добавить метод вывода средств
     * @returns PayoutMethodResponseDTO Данные метода вывода средств только если был добавлен новый метод.
     * @throws ApiError
     */
    public addPayoutMethod({
        requestBody,
    }: {
        requestBody: AddPayoutMethodDTO,
    }): CancelablePromise<PayoutMethodResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payout/method',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка добавления метода вывода средств`,
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Выбрать метод вывода средств по умолчанию
     * @returns void
     * @throws ApiError
     */
    public setDefaultPayoutMethod({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/payout/method/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден метод вывода средств`,
            },
        });
    }
    /**
     * Удалить метод вывода средств
     * @returns void
     * @throws ApiError
     */
    public deletePayoutMethod({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/payout/method/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Неверные данные для авторизации`,
                404: `Не найден метод вывода средств который вы хотите удалить`,
            },
        });
    }
    /**
     * Список методов вывода средств пользователя
     * @returns PayoutMethodResponseDTO
     * @throws ApiError
     */
    public getPayoutMethodList(): CancelablePromise<Array<PayoutMethodResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payout/method/list',
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
    /**
     * Список выплат
     * @returns PagePayoutResponseDTO Список выплат
     * @throws ApiError
     */
    public getPayoutsList({
        owner,
        createdAtFrom,
        createdAtTo,
        page = 1,
        limit = 10,
    }: {
        owner?: string,
        createdAtFrom?: string,
        createdAtTo?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PagePayoutResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payout/list',
            query: {
                'owner': owner,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `Неверные данные для авторизации`,
            },
        });
    }
}
