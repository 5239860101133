import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface ConfirmDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  callback: () => void;
  title: string;
  text: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  setOpen,
  callback,
  title,
  text,
}) => {
  const { t } = useTranslation();
  const onConfirm = () => {
    setOpen(false);
    callback();
  };

  const onClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button autoFocus onClick={onClose} variant="outlined">
          {t("common.cancel")}
        </Button>

        <Button onClick={onConfirm} variant="contained">{t("common.yes")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
