/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EcsResponseDTO } from '../models/EcsResponseDTO';
import type { PageEcsResponseDTO } from '../models/PageEcsResponseDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EcsPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Список ECS
     * Список ECS с пагинацией
     * @returns PageEcsResponseDTO Список Ecs
     * @throws ApiError
     */
    public getPublicEcsList({
        search,
        connectorTypes,
        statuses,
        types,
        isFree,
        isFavorite,
        owner,
        sortLocation,
        page = 1,
        limit = 10,
    }: {
        /**
         * Поиск по имени станции.
         */
        search?: string,
        connectorTypes?: Array<'none' | 'type1' | 'type2' | 'chademo' | 'cssC1' | 'cssC2' | 'gbt'>,
        statuses?: Array<'Available' | 'Occupied' | 'Charging' | 'Reserved' | 'Unavailable' | 'Faulted'>,
        types?: Array<'slow' | 'fast'>,
        isFree?: boolean,
        isFavorite?: boolean,
        owner?: string,
        /**
         * The point for which we return the documents from the nearest to the farthest.
         */
        sortLocation?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PageEcsResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/ecs/list',
            query: {
                'search': search,
                'connectorTypes': connectorTypes,
                'statuses': statuses,
                'types': types,
                'isFree': isFree,
                'isFavorite': isFavorite,
                'owner': owner,
                'sortLocation': sortLocation,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Список ECS для карты
     * Список ECS без пагинации
     * @returns EcsResponseDTO Список ECS
     * @throws ApiError
     */
    public getPublicEcsMapAll({
        search,
        connectorTypes,
        statuses,
        types,
        isFree,
        isFavorite,
        owner,
        sortLocation,
    }: {
        /**
         * Поиск по имени станции.
         */
        search?: string,
        connectorTypes?: Array<'none' | 'type1' | 'type2' | 'chademo' | 'cssC1' | 'cssC2' | 'gbt'>,
        statuses?: Array<'Available' | 'Occupied' | 'Charging' | 'Reserved' | 'Unavailable' | 'Faulted'>,
        types?: Array<'slow' | 'fast'>,
        isFree?: boolean,
        isFavorite?: boolean,
        owner?: string,
        /**
         * The point for which we return the documents from the nearest to the farthest.
         */
        sortLocation?: string,
    }): CancelablePromise<Array<EcsResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/ecs/list/map',
            query: {
                'search': search,
                'connectorTypes': connectorTypes,
                'statuses': statuses,
                'types': types,
                'isFree': isFree,
                'isFavorite': isFavorite,
                'owner': owner,
                'sortLocation': sortLocation,
            },
        });
    }
    /**
     * ECS
     * @returns EcsResponseDTO ECS
     * @throws ApiError
     */
    public getPublicEcs({
        id = '61d9cfbf17ed7311c4b3e485',
    }: {
        /**
         * Id
         */
        id?: string,
    }): CancelablePromise<EcsResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/ecs/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Не найден ECS`,
            },
        });
    }
}
