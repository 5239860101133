/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EcsTransactionConnectorResponseDTO = {
    power: number;
    connector: EcsTransactionConnectorResponseDTO.connector;
};
export namespace EcsTransactionConnectorResponseDTO {
    export enum connector {
        NONE = 'none',
        TYPE1 = 'type1',
        TYPE2 = 'type2',
        CHADEMO = 'chademo',
        CSS_C1 = 'cssC1',
        CSS_C2 = 'cssC2',
        GBT = 'gbt',
    }
}

